import React from 'react';

import { Badge } from 'react-bootstrap';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

export default function OutOfPlayStack(props) {
	let { stack } = props;

	return (
	  <OverlayTrigger placement="top" overlay={<Tooltip>ADDING TO STACK AFTER THIS HAND</Tooltip>}>
			<Badge className="out-of-play-stack p-1" variant="secondary">+ ${stack}</Badge>
	  </OverlayTrigger>
	)
}