import React from 'react';
import { Badge, Image } from 'react-bootstrap';
// import ResizeObserver from 'react-resize-observer';
// import delay from 'delay';
// import { Keyframes, animated, config } from 'react-spring/renderprops'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck as checkIcon,
} from '@fortawesome/free-solid-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import './ChipStack.css'

import env from '../env'

const CHIPS_PER_STACK = 5;
export const chips = [
  { value: 1000, color: 'gray', rgb: '#A5A5A5'},
  { value: 500, color: 'purple', rgb: '#8F2DEE'},
  { value: 100, color: 'black', rgb: '#000000'},
  { value: 25, color: 'green', rgb: '#345C17'},
  { value: 5, color: 'red', rgb: '#a22025'},
  { value: 1, color: 'blue', rgb: '#4E8EE6'}
];

const getChipPosition = (index, top) => {
  return {top: (index * -.2) + 'vmin'};
}

const createChip = (chip, stackIndex, chipIndex, top) => {
  const name = top ? 'top' : 'stacker';
  return <Image className="chip" key={`chip${stackIndex}-${top ? top : chipIndex}`} style={getChipPosition(top ? -1 : chipIndex)} src={env.imageUrl(`/deck/${chip.color}-chip-${name}.png`)} />;
}

const ChipStack = (props) => {
  var { amount, award, check, tooltip, containerClass } = props;
  if (!award && check) return <div className={`chip-stacks-container ${containerClass ? containerClass : ''}`}><FontAwesomeIcon icon={checkIcon} size="2x" inverse /></div>;

  var remaining = award ? award : amount ? amount : null;
  if (!remaining) return null;

  var chipCounts = chips.map(chip => { return {chip: chip, count: 0 }});
  while (remaining > 0) {
    var chipCount = chipCounts.find(chipCount => chipCount.chip.value <= remaining);
    chipCount.count++;
    remaining -= chipCount.chip.value;
  }
  chipCounts = chipCounts.filter(chipCount => chipCount.count > 0);

  const createChips = (chip, num, stackIndex, chipIndex) => {
    let chips = [];
    for (let i = 0; i < num; i++) chips.push(createChip(chip, stackIndex, chipIndex + i));
    return chips;
  }

  let chipStacks = [];
  for (let i = chipCounts.length - 1; i >= 0; i--) {
    let chipCount = chipCounts[i];
    let stackIndex = chipStacks.length;
    let stack = [createChip(chipCount.chip, chipStacks.length, null, true)];
    if (chipCount.count >= CHIPS_PER_STACK) {
      for (let j = 0; j < CHIPS_PER_STACK; j++) stack.push(createChip(chipCount.chip, stackIndex, stack.length));
      chipCount.count -= CHIPS_PER_STACK;
      i++;
    } else {
      stack = stack.concat(createChips(chipCount.chip, chipCount.count, stackIndex, stack.length));
      let stackSize = chipCount.count;
      while (i > 0 && stackSize + chipCounts[i-1].count <= CHIPS_PER_STACK) {
        chipCount = chipCounts[--i];
        stack = stack.concat(createChips(chipCount.chip, chipCount.count, stackIndex, stack.length));
        stackSize += chipCount.count;
      }
    }
    chipStacks.unshift(stack);
  }

  // const [positionX, setPositionX] = useState(0);
  // const [positionY, setPositionY] = useState(0);

  // const capturePosition = rect => {
  //   setPositionX(rect.x);
  //   setPositionY(rect.y);
  // }

  // const AnimatedStack = Keyframes.Spring({
  //   initial: {opacity: 1},
  //   rake: async (next) => {
  //     next({
  //       from: {left: positionX, top: positionY},
  //       to: {position: 'fixed', right: 'unset', bottom: 'unset', ...props.rakePosition},
  //       config: config.default
  //     })
  //     await delay(500)
  //     await next({
  //       from: { opacity: 1 },
  //       to: { opacity: 0 },
  //       config: config.default
  //     })
  //   }
  // });

  const chipStack = <div className={`chip-stacks-container ${containerClass ? containerClass : ''}`}>
      <div className="chip-stacks">
        { chipStacks.map((chipStack, i) => (
          <div className="chip-stack" style={{ right: `${.8 * i}vw`, zIndex: 5 - chipStack.length}} key={`chipStack${i}`}>{chipStack}</div>
        ))}
      </div>
      { !amount && award && <Badge variant="success" style={{ marginTop: '.6vh', fontSize: '2vmin' }}>+${award}</Badge> }
      { amount && !award && <span className="chip-amount" style={{ right: `${.3 * chipStacks.length}vw`}}>${amount}</span> }
      { props.children }
    </div>;

  if (tooltip) return <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>} >{ chipStack }</OverlayTrigger>;
  return chipStack;

  // return 	(
    // <AnimatedStack native state={props.rake ? 'rake' : 'initial'}>
    //   {style => (
    //     <animated.div className={`chip-stacks-container ${containerClass}`} style={{...props.initialStyle, ...style}}>
    //       <div className="chip-stacks">
    //         { chipStacks.map((chipStack, i) => (
    //           <div className="chip-stack" key={`chipStack${i}`}>{chipStack}</div>
    //         ))}
    //       </div>
    //       { props.displayAmount && <span className="chip-amount">${props.amount}</span> }
    //       { !props.rake && <ResizeObserver onPosition={capturePosition} /> }
    //     </animated.div>
    //   )}
    // </AnimatedStack>

  // );
}

export default ChipStack;