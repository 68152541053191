import React from 'react';

class UiSettings {

	constructor(onSettingsChanged) {
		this.onSettingsChanged = onSettingsChanged;
	}

	get layout() {
		return localStorage.getItem('settings.ui.layout') || 'tile';
	}

	set layout(layout) {
		localStorage.setItem('settings.ui.layout', layout);

		let l = this.onSettingsChanged;
		if (l) l('settings.ui.layout', layout);
	}

}

class AVSettings {

	constructor(onSettingsChanged) {
		this.onSettingsChanged = onSettingsChanged;
	}

	get audioInputDevice() {
		return sessionStorage.getItem('settings.audioVideo.audioInputDevice');
	}

	set audioInputDevice(deviceId) {
		sessionStorage.setItem('settings.audioVideo.audioInputDevice', deviceId);
	}

	get audioOutputDevice() {
		return sessionStorage.getItem('settings.audioVideo.audioOutputDevice');
	}

	set audioOutputDevice(deviceId) {
		sessionStorage.setItem('settings.audioVideo.audioOutputDevice', deviceId);
	}

	get videoInputDevice() {
		return sessionStorage.getItem('settings.audioVideo.videoInputDevice');
	}

	set videoInputDevice(deviceId) {
		sessionStorage.setItem('settings.audioVideo.videoInputDevice', deviceId);
	}

	get muted() {
		let storageValue = sessionStorage.getItem('settings.audioVideo.muted');

		return storageValue === 'true';
	}

	set muted(muted) {
		sessionStorage.setItem('settings.audioVideo.muted', muted);

		let l = this.onSettingsChanged;
		if (l) l('settings.audioVideo.muted', muted);
	}

	get videoDisabled() {
		let storageValue = sessionStorage.getItem('settings.audioVideo.videoDisabled');

		return storageValue === 'true';
	}

	set videoDisabled(videoDisabled) {
		sessionStorage.setItem('settings.audioVideo.videoDisabled', videoDisabled);

		let l = this.onSettingsChanged;
		if (l) l('settings.audioVideo.videoDisabled', videoDisabled);
	}

}

class DealersChoiceSettings {

	constructor(onSettingsChanged) {
		this.onSettingsChanged = onSettingsChanged;

		this.ui = new UiSettings(onSettingsChanged);
		this.audioVideo = new AVSettings(onSettingsChanged);
	}

}

const SettingsContext = React.createContext(new DealersChoiceSettings());

export { DealersChoiceSettings, SettingsContext };
