import React from 'react';

import { Button, Form, Modal } from 'react-bootstrap';

class AddChipsDialog extends React.Component {

	state = { valid: false }

	updateChips(e) {
		var chips = parseInt(e.target.value);
		var valid = !isNaN(chips);
		this.setState({ chips: valid ? chips : undefined, valid: valid });
	}

	addChips() {
		var addChips = this.props.addChips;

		addChips(this.state.chips);

		this.setState({ chips: undefined, valid: false });
	}

	render() {
		var chips = this.state.chips ? this.state.chips : '';
		var valid = this.state.valid;
		var onSubmit = e => {
			e.preventDefault();

			if (this.state.valid) this.addChips();
		}

		return (
			<Modal show={this.props.show} onHide={this.props.onHide} centered scrollable dialogClassName="modal-90w">
			  <Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Add Chips</Modal.Title>
			  </Modal.Header>
				
			  <Modal.Body>
					<Form onSubmit={onSubmit}>
						<Form.Group controlId="formAddChips">
							<Form.Control autoComplete="off" placeholder="Enter number of chips" value={chips} onChange={e => this.updateChips(e)} />
						</Form.Group>
						{ this.props.outOfPlay && <div className="small my-2">These chips will be added to your stack at the beginning of the next hand.</div> }
						<Button variant="success my-2" disabled={!valid} onClick={z => this.addChips()}>Add</Button>
					</Form>
			  </Modal.Body>
			</Modal>
		);
	}
}

export default AddChipsDialog;