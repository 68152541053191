import React from 'react';

/**
 * represents a best guess at the latency between browser and server
 */
const SKEW = 100;

function createServerTime(ts) {
	let updatedTs = Date.now();

	return {
		lastServerTs: ts,
		updatedTs,
		currentTime: z => ts + (Date.now() - updatedTs - SKEW)
	}
}

const ServerTime = React.createContext(createServerTime(Date.now()));

export {
	createServerTime,
	ServerTime
}
