// import NewSession from './NewSession'
import HostSession from './HostSession'

/**
 * "landing page" for flows that don't join a current session
 * 
 * @returns 
 */
export default function Landing({ ecosystem }) {
	return (
		<HostSession ecosystem={ecosystem} />
	)
}