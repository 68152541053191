import React from 'react';

import { Sound } from '@circleleague/ui';

const SOUNDS = {
	shuffle: '/shuffle.wav',
	deal: '/sound-deal2.mp3',
	check: '/sound-knock1.wav',
	call: '/sound-bet2.mp3',
	bet: '/sound-bet1.mp3',
	ante: '/sound-bet2.mp3',
	raise: '/sound-bet3.mp3',
	fold: '/sound-deal3.mp3',
	choose: '/sound-deal2.mp3',
	exchange: '/sound-deal2.mp3',
	alert: '/sound-win1.mp3',
}

const MemoSound = React.memo(props => {
	return <Sound source={props.source} />
})

function SessionSounds(props) {
	let { session } = props;

	let activeHand = session && session.table ? session.table.activeHand : undefined;

	if (!activeHand) return null;

	return <MemoSound activeHand={activeHand} source={'/shuffle.wav'} />
}

function HandSounds(props) {
	let { hand } = props;

	let lastAction, handSource;
	if (hand && hand.lastAction) {
		lastAction = hand.lastAction;

		if (lastAction.action === 'match') handSource = SOUNDS[lastAction.commonName];
		else handSource = SOUNDS[lastAction.action];
	}

	return handSource ? <MemoSound source={handSource} yieldId={hand.sequence.yieldId} /> : null;
}

export default function Sounds(props) {
	let { session, hand } = props;

	return (
		<>
		  <SessionSounds session={session} />
		  <HandSounds hand={hand} />
		</>
	)
}