import React, { useState } from 'react';

import { Badge, Modal } from 'react-bootstrap';
import { CardCollection } from '../deck/CardCollection';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import GameRulesTable from './GameRulesTable';

import './GameRules.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExchangeAlt as exchange,
	faHandPointLeft as push,
	faHandPointDown as bid,
 } from '@fortawesome/free-solid-svg-icons'
	
const rules = [
	{ id: 'holdem',
		title: 'Pot Limit Hold \'Em',
		board: {
			layout: [[0, 1, 2, 3, 4]],
			cards: [{id: '3d'},{id: 'kh'},{id: 'as'}],
			highHand: [
				{id: '3d', inBestHand: false},
				{id: 'kh', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: false},
				{id: 'ad', inBestHand: true, inWinningHand: true},
			]
		},
		players: [{
			layout: [[0, 1]],
			cards: [
				{id: 'ac'},
				{id: 'kd'},
			],
			highHand: [
				{id: 'ac', inBestHand: true, inWinningHand: true},
				{id: 'kd', inBestHand: true, inWinningHand: true},
			]
		}, {
			layout: [[0, 1]],
			facedown: true
		}],
		handComposition: [{type: 'highHand', text: 'High: Any 5-card combo of player cards + board cards.'}],
		rounds: [
			{ type: 'deal' },
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'}], facedown: true},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'}], facedown: true},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'},{id: 'ad'}]},
			{ type: 'end' }
		]
	},
	{ id: 'omaha',
		title: 'Omaha',
		highLow: true,
		board: {
			layout: [[0, 1, 2, 3, 4]],
			cards: [{id: '3d'},{id: 'kh'},{id: 'as'}],
			highHand: [
				{id: '3d', inBestHand: false},
				{id: 'kh', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: false},
				{id: 'ad', inBestHand: true, inWinningHand: true},
			],
			lowHand: [
				{id: '3d', inBestHand: true, inWinningHand: true},
				{id: 'kh', inBestHand: false},
				{id: 'as', inBestHand: false},
				{id: '6c', inBestHand: true, inWinningHand: true},
				{id: 'ad', inBestHand: true, inWinningHand: true}
			]
		},
		players: [{
			layout: [[0, 1, 2, 3]],
			cards: [
				{id: '2h'},
				{id: 'ac'},
				{id: 'kd'},
				{id: '4d'}
			],
			highHand: [
				{id: '2h', inBestHand: false},
				{id: 'ac', inBestHand: true, inWinningHand: true},
				{id: 'kd', inBestHand: true, inWinningHand: true},
				{id: '4d', inBestHand: false},
			],
			lowHand: [
				{id: '2h', inBestHand: true, inWinningHand: true},
				{id: 'ac', inBestHand: false},
				{id: 'kd', inBestHand: false},
				{id: '4d', inBestHand: true, inWinningHand: true},
			],
		}, {
			layout: [[0, 1, 2, 3]],
			facedown: true
		}],
		handComposition: [
			{type: 'both', text: 'High & Low: Exactly 2 player cards + 3 board cards.'}
		],
		rounds: [
			{ type: 'deal' },
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'}], facedown: true},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'}], facedown: true},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'},{id: 'ad'}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'draw_five',
		title: 'Five Card Draw',
		highLow: true,
		exchange: true,
		table: {
			style: { height: '280px' }
		},
		players: [{
			style: { top: '63%'},
			layout: [[0, 1, 2, 3, 4]],
			cards: [{id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js'}, {id: '10s'}],
			highHand: [
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: 'qs', inBestHand: true, inWinningHand: true},
				{id: 'js', inBestHand: true, inWinningHand: true},
				{id: '10s', inBestHand: true, inWinningHand: true}
			]
		}, {
			style: { bottom: '60%' },
			layout: [[0, 1, 2, 3, 4]],
			lowHand: [
				{id: '3s', inBestHand: true, inWinningHand: true},
				{id: 'ah', inBestHand: true, inWinningHand: true},
				{id: '4h', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: true, inWinningHand: true}
			],
			facedown: true
		}],
		handComposition: [
			{type: 'both', text: 'High & Low: All 5 player cards.'}
		],
		rounds: [
			{ type: 'deal'},
			{ type: 'exchange'},
			{ type: 'declare'},
			{ type: 'end' }
		]
	},
	{ id: 'fifty_two',
		title: 'Fifty Two',
		highLow: true,
		exchange: true,
		board: {
			layout: [[0, 1]],
			cards: [{},{}],
			highHand: [
				{id: 'js', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: true, inWinningHand: true},
			],
			lowHand: [
				{id: 'js', inBestHand: false},
				{id: 'ks', inBestHand: false},
			]
		},
		players: [{
			layout: [[0, 1, 2, 3, 4]],
			cards: [{id: 'as'}, {id: '8h'}, {id: 'qs'}, {id: '5d'}, {id: '10s'}],
			highHand: [
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '8h', inBestHand: false},
				{id: 'qs', inBestHand: true, inWinningHand: true},
				{id: '5d', inBestHand: false},
				{id: '10s', inBestHand: true, inWinningHand: true}
			]
		}, {
			style: { bottom: '50%' },
			layout: [[0, 1, 2, 3, 4]],
			lowHand: [
				{id: '3s', inBestHand: true, inWinningHand: true},
				{id: 'ah', inBestHand: true, inWinningHand: true},
				{id: '4h', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: true, inWinningHand: true}
			],
			facedown: true
		}],
		handComposition: [
			{type: 'both', text: ['High & Low: All 5 player cards', 'or 3 player cards + both board cards.']}
		],
		rounds: [
			{ type: 'deal'},
			{ type: 'reveal', cards: [{id: 'js'}], facedown: true},
			{ type: 'exchange'},
			{ type: 'reveal', cards: [{id: 'js'},{id: 'ks'}], facedown: true},
			{ type: 'declare'},
			{ type: 'end' }
		]
	},
	{ id: 'iron_cross',
		title: 'Iron Cross',
		highLow: true,
		table: {
			style: { height: '380px' }
		},
		board: {
			style: { top: '-83px' },
			layout: [[-1, 0, -1],[3, 4, 1], [-1, 2, -1]],
			highHand: [
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: '2d', inBestHand: false},
				{id: 'qd', inBestHand: true, inWinningHand: true},
				{id: '3h', inBestHand: false},
				{id: 'as', inBestHand: true, inWinningHand: true}
			],
			lowHand: [
				{id: 'ks', inBestHand: false},
				{id: '2d', inBestHand: true, inWinningHand: true},
				{id: 'qd', inBestHand: false},
				{id: '3h', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true}
			]
		},
		players: [{
			style: { top: '88%'},
			layout: [[0, 1]],
			cards: [{id: 'jc'}, {id: '10d'}],
			highHand: [
				{id: 'jc', inBestHand: true, inWinningHand: true},
				{id: '10d', inBestHand: true, inWinningHand: true},
			]
		}, {
			style: { bottom: '38%' },
			layout: [[0, 1]],
			lowHand: [
				{id: '6h', inBestHand: true, inWinningHand: true},
				{id: '5d', inBestHand: true, inWinningHand: true},
			],
			facedown: true
		}],
		handComposition: [
			{type: 'both', text: ['High & Low: Both player cards', '+ either the board row or the board column.']}
		],
		rounds: [
			{ type: 'deal' },
			{ type: 'reveal', cards: [{id: 'ks'},{id: '2d'}], facedown: true},
			{ type: 'reveal', cards: [{id: 'ks'},{id: '2d'},{id: 'qd'},{id: '3h'}], facedown: true},
			{ type: 'reveal', cards: [{id: 'ks'},{id: '2d'},{id: 'qd'},{id: '3h'},{id: 'as'}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'two_two_one',
		title: '2-2-1',
		highLow: true,
		table: {
			style: { height: '360px' }
		},
		board: {
			style: { top: '-78px' },
			layout: [[-1, -1, -1, 0, 1], [2, 3, 4, 5, 6]],
			highHand: [
				{id: '3d', inBestHand: false},
				{id: 'kh', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: false},
				{id: '9c', inBestHand: false},
				{id: 'ad', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: false}
			],
			lowHand: [
				{id: '3d', inBestHand: true, inWinningHand: true},
				{id: 'kh', inBestHand: false},
				{id: 'as', inBestHand: false},
				{id: '6c', inBestHand: true, inWinningHand: true},
				{id: '9c', inBestHand: false},
				{id: 'ad', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: false}
			]
		},
		players: [{
			style: { top: '82%'},
			layout: [[0, 1, 2, 3]],
			cards: [{id: '4c'}, {id: 'ac'}, {id: 'kd'}, {id: 'qh'}],
			highHand: [
				{id: '4c', inBestHand: false},
				{id: 'ac', inBestHand: true, inWinningHand: true},
				{id: 'kd', inBestHand: true, inWinningHand: true},
				{id: 'qh', inBestHand: false},
			]
		}, {
			style: { bottom: '43%' },
			layout: [[0, 1, 2, 3]],
			lowHand: [
				{id: '2h', inBestHand: true, inWinningHand: true},
				{id: 'js', inBestHand: false},
				{id: '4d', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: false}
			],
			facedown: true
		}],
		handComposition: [
			{type: 'both', text: ['High & Low: 2 player cards', '+ 2 cards from bottom board row', '+ 1 card from top board row.']},
		],
		rounds: [
			{ type: 'deal' },
			{ type: 'reveal', cards: [{},{},{id: 'as'},{id: '6c'},{id: '9c'},{},{}]},
			{ type: 'reveal', cards: [{id: '3d'},{},{id: 'as'},{id: '6c'},{id: '9c'},{id: 'ad'},{}]},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'},{id: '9c'},{id: 'ad'},{id: 'ks'}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'the_bluffs',
		title: 'The Bluffs',
		highLow: true,
		table: {
			style: { height: '360px' }
		},
		board: {
			style: { top: '-78px' },
			layout: [[0, 1, 2, 3], [4, 5, 6, 7]],
			highHand: [
				{id: '3d', inBestHand: false},
				{id: 'kh', inBestHand: false},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: false},
				{id: '9c', inBestHand: false},
				{id: '4h', inBestHand: false},
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: 'ah', inBestHand: false}
			],
			lowHand: [
				{id: '3d', inBestHand: true, inWinningHand: true},
				{id: 'kh', inBestHand: false},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: true, inWinningHand: true},
				{id: '9c', inBestHand: false},
				{id: '4h', inBestHand: false},
				{id: 'ks', inBestHand: false},
				{id: 'ah', inBestHand: false}
			]
		},
		players: [{
			style: { top: '82%'},
			layout: [[0, 1, 2, 3]],
			cards: [{id: 'qd'}, {id: 'ac'}, {id: 'kh'}, {id: 'kc'}],
			highHand: [
				{id: 'qd', inBestHand: false},
				{id: 'ac', inBestHand: true, inWinningHand: true},
				{id: 'kh', inBestHand: true, inWinningHand: true},
				{id: 'kc', inBestHand: true, inWinningHand: true},
			]
		}, {
			style: { bottom: '43%' },
			layout: [[0, 1, 2, 3]],
			lowHand: [
				{id: '2h', inBestHand: true, inWinningHand: true},
				{id: 'jc', inBestHand: false},
				{id: '4d', inBestHand: true, inWinningHand: true},
				{id: '9s', inBestHand: false}
			],
			facedown: true
		}],
		handComposition: [
			{ type: 'highHand', text: 'High: 3 player cards + any board column'},
			{ type: 'lowHand', text: 'Low: 2 player cards + 3 cards from either board row'}
		],
		rounds: [
			{ type: 'deal' },
			{ type: 'reveal', cards: [{id: '3d'},{},{},{},{},{},{},{id: 'ah'}]},
			{ type: 'reveal', cards: [{id: '3d'},{},{id: 'as'},{},{},{id: 'ad'},{},{id: 'ah'}]},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{},{},{id: 'ad'},{id: 'ks'},{id: 'ah'}]},
			{ type: 'reveal', cards: [{id: '3d'},{id: 'kh'},{id: 'as'},{id: '6c'},{id: '9c'},{id: 'ad'},{id: 'ks'},{id: 'ah'}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'seven_stud',
		title: 'Seven Card Stud',
		highLow: true,
		table: {
			style: { height: '280px' }
		},
		players: [{
			style: { top: '62%'},
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{id: '7d', concealed: true},{id: '10s', concealed: true},{id: 'as'},{id: 'ks'},{id: 'qs'},{id: 'js'},{id: '2d', concealed: true}],
			highHand: [
				{id: '7d', inBestHand: false},
				{id: '10s', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: 'qs', inBestHand: true, inWinningHand: true},
				{id: 'js', inBestHand: true, inWinningHand: true},
				{id: '2d', inBestHand: false}
			]
		}, {
			style: { bottom: '60%' },
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{},{},{id: 'ah'},{id: '4h'},{id: '7h'},{id: 'kh'},{}],
			lowHand: [
				{id: '3s', inBestHand: true, inWinningHand: true},
				{id: 'jc', inBestHand: false},
				{id: 'ah', inBestHand: true, inWinningHand: true},
				{id: '4h', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: true, inWinningHand: true},
				{id: 'kh', inBestHand: false},
				{id: '6c', inBestHand: true, inWinningHand: true}
			],
		}],
		handComposition: [
			{type: 'both', text: 'High & Low: Any 5 player cards.'}
		],
		rounds: [
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js'}, {id: '2d', concealed: true}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'push',
		title: 'Push',
		highLow: true,
		push: true,
		table: {
			style: { height: '280px' }
		},
		players: [{
			style: { top: '62%'},
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs', pushKeep: true}],
			highHand: [
				{id: '7d', inBestHand: false},
				{id: '10s', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: 'qs', inBestHand: true, inWinningHand: true},
				{id: 'js', inBestHand: true, inWinningHand: true},
				{id: '2d', inBestHand: false}
			]
		}, {
			style: { bottom: '60%' },
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{},{},{id: 'ah'},{id: '4h'}],
			lowHand: [
				{id: '3s', inBestHand: true, inWinningHand: true},
				{id: '10c', inBestHand: false},
				{id: 'ah', inBestHand: true, inWinningHand: true},
				{id: '4h', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: true, inWinningHand: true},
				{id: 'jc', inBestHand: false},
				{id: '6c', inBestHand: true, inWinningHand: true}
			],
		}],
		handComposition: [
			{type: 'both', text: 'High & Low: Any 5 player cards.'}
		],
		rules: [
			'7-card hand starting with 2 cards down.',
			'At the start of each of the first four rounds, a card is dealt face-up to a single rotating player.',
			'The player may decide to keep the card or pay $3 to push it to the opponent to the left.',
			'That player then has the option to keep or push.',
			'If the card is kept by any player, the next player in order is dealt a card, with the option to keep or push.',
			'At the end of each round, any player that did not keep a card is dealt a new card that must be kept.',
			'In the fifth round, each player is dealt a final card face down.'
		],
		rounds: [
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as', pushKeep: true}], icons: [{type: 'push', tooltip: 'Option to keep or push card'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks', pushKeep: true}], icons: [{type: 'push', tooltip: 'Option to keep or push card'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs', pushKeep: true}], icons: [{type: 'push', tooltip: 'Option to keep or push card'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js', pushKeep: true}], icons: [{type: 'push', tooltip: 'Option to keep or push card'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js'}, {id: '2d', concealed: true}], icons: [{type: 'spacer'}]},
			{ type: 'declare' },
			{ type: 'end' }
		]
	},
	{ id: 'auction',
		title: 'Auction',
		highLow: true,
		keep: true,
		table: {
			style: { height: '320px' }
		},
		board: {
			style: { top: '-70px' },
			layout: [[0, 1]],
			cards: [{id: 'js', pushKeep: true},{id: '8h'},],
			highHand: [
				{id: '3d', inBestHand: false},
				{id: 'kh', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: '6c', inBestHand: false},
				{id: 'ad', inBestHand: true, inWinningHand: true},
			]
		},
		players: [{
			style: { top: '73%'},
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{id: '7d', concealed: true},{id: '10s', concealed: true},{id: 'as'},{id: 'ks'},{id: 'qs'}],
			highHand: [
				{id: '7d', inBestHand: false},
				{id: '10s', inBestHand: true, inWinningHand: true},
				{id: 'as', inBestHand: true, inWinningHand: true},
				{id: 'ks', inBestHand: true, inWinningHand: true},
				{id: 'qs', inBestHand: true, inWinningHand: true},
				{id: 'js', inBestHand: true, inWinningHand: true},
				{id: '2d', inBestHand: false}
			]
		}, {
			style: { bottom: '46%' },
			layout: [[0, 1, 2, 3, 4, 5, 6]],
			cards: [{},{},{id: 'ah'},{id: '4h'},{id: '7h'}],
			lowHand: [
				{id: '3s', inBestHand: true, inWinningHand: true},
				{id: '10c', inBestHand: false},
				{id: 'ah', inBestHand: true, inWinningHand: true},
				{id: '4h', inBestHand: true, inWinningHand: true},
				{id: '7h', inBestHand: true, inWinningHand: true},
				{id: 'jc', inBestHand: false},
				{id: '6c', inBestHand: true, inWinningHand: true}
			],
		}],
		handComposition: [
			{type: 'both', text: 'High & Low: Any 5 player cards.'}
		],
		rules: [
			'7-card hand starting with 2 cards down.',
			'At the start of each of the first 4 rounds, one card for each player is dealt to the board.',
			'Each player places a bid of $0 or more for the right to select a card first. All bids are added to the pot.',
			'Each player selects a card from the board in the order of highest bid to lowest.',
			'If a player bids $0 more than once, that player\'s hand is automatically folded.',
			'If there is a tie bid, the player that selects first rotates starting with left of the dealer.',
			'In the fifth round, each player is dealt a final card face down.'
		],
		rounds: [
 			{ type: 'bid', boardLayout: [[0,1]], boardCards: [{id: 'ah'}, {id: 'as'}], layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as', pushKeep: true}], icons: [{type: 'bid', tooltip: 'Select card from board in order of highest bid to lowest.'}]},
 			{ type: 'bid', boardLayout: [[0,1]], boardCards: [{id: 'ks'}, {id: '4h'}], layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks', pushKeep: true}], icons: [{type: 'bid', tooltip: 'Select card from board in order of highest bid to lowest.'}]},
 			{ type: 'bid', boardLayout: [[0,1]], boardCards: [{id: '7h'}, {id: 'qs'}], layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs', pushKeep: true}], icons: [{type: 'bid', tooltip: 'Select card from board in order of highest bid to lowest.'}]},
 			{ type: 'bid', boardLayout: [[0,1]], boardCards: [{id: 'js'}, {id: '8h'}], layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js', pushKeep: true}], icons: [{type: 'bid', tooltip: 'Select card from board in order of highest bid to lowest.'}]},
 			{ type: 'reveal', layout: [[0, 1, 2, 3, 4, 5, 6]], cards: [{id: '7d', concealed: true}, {id: '10s', concealed: true}, {id: 'as'}, {id: 'ks'}, {id: 'qs'}, {id: 'js'}, {id: '2d', concealed: true}]},
			{ type: 'declare' },
			{ type: 'end' }
		],
		roundStyle: { width: '100%' }
	}
];

const bet = () => {
	return <div>Bet</div>
}

const getIcon = (icon) => {
	switch (icon.type) {
		case 'push' : return <OverlayTrigger placement="top" overlay={<Tooltip>{icon.tooltip}</Tooltip>} >
				<Badge className="mx-1 round-icon" variant="info"><FontAwesomeIcon icon={push} /></Badge>
			</OverlayTrigger>;
		case 'bid' : return <OverlayTrigger placement="top" overlay={<Tooltip>{icon.tooltip}</Tooltip>} >
				<Badge className="mx-1 round-icon" variant="info"><FontAwesomeIcon icon={bid} /></Badge>
			</OverlayTrigger>;
		case 'exchange' : return <Badge className="mx-1 round-icon" variant="info"><FontAwesomeIcon icon={exchange} /></Badge>;
		case 'spacer' : return <div className="mx-1" style={{width: '20px'}}></div>;
		default: return null;
	}
}

const icons = (icons) => {
	return icons ? icons.map(icon => getIcon(icon)) : null;
}

const round = (game, round) => {
	switch (round.type) {
		case 'deal' : return <><div>Deal</div>{ icons(round.icons) }{ bet() }</>;
		case 'reveal' : return <>
			<CardCollection
				layout={Array.isArray(round.layout) ? round.layout : round.layout === 'player' ? game.player.layout : game.board.layout }
				cards={round.cards} masked={true} isBoard={round.facedown}/>
			{ icons(round.icons) }{ bet() }
		</>;
		case 'bid' : return <>
			<CardCollection layout={round.boardLayout } cards={round.boardCards} isBoard={true}/>
			<div className="d-flex flex-column">
			<div>Bid</div>
			{ icons(round.icons) }
			</div>
			<CardCollection
				layout={Array.isArray(round.layout) ? round.layout : round.layout === 'player' ? game.player.layout : game.board.layout }
				cards={round.cards} masked={true} isBoard={round.facedown}/>
		</>;
		case 'exchange' : return <><div>Exchange up to 3 cards</div>{ icons(round.icons) }{ bet() }</>;
		case 'declare' : return <><div>Declare High, Low, or Both</div>{ icons(round.icons) }{ bet() }</>;
		case 'end' : return <span className="end">Hand complete. {game.highLow ? 'Best high & low hands win.' : 'Best high hand wins.'}</span>;
		default: return null;
	}
}

const rounds = (game) => {
	return <div className="rounds-container">
		{ game.rounds.map((r, i) => {
			return <div key={i} className="round" style={ game.roundStyle }>
				{ r.type !== 'end' && <Badge className="round-number" variant="info">Round<br/>{ i + 1 }</Badge> }
				{ round(game, r) }
			</div>;
		})}
	</div>
}

export default function GameRulesDialog(props) {
	let { game, show, onHide } = props;
	const [highlight, setHighlight] = useState(null);

	const onMouseExit = () => {
		setHighlight(null);
	}

	var gameRules = rules.find(rules => rules.id === game.implementation);
	var numCards = gameRules.players[0].layout[0].length + (gameRules.board ? gameRules.board.layout.reduce((num, row) => {return num + row.length;}, 0) : 0);
	let lowVariant = game.descriptor && game.descriptor.tags ? game.descriptor.tags.find(tag => tag.category === 'low') : null;

	return (
		<Modal show={show} onHide={onHide} centered scrollable dialogClassName="game-rules" onHide={onHide}>
		  <Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{game.title}</Modal.Title>
		  </Modal.Header>			
		  <Modal.Body>
				<GameRulesTable gameRules={ gameRules } lowVariant={lowVariant ? lowVariant.label : null} highlight={ highlight } setHighlight={ setHighlight }/>
				{ gameRules.handComposition && <h6 className="text-center">
						{ gameRules.handComposition.map((comp, i) =>
							<div key={i} className={`my-2 hand-composition ${highlight === comp.type ? 'highlight' : ''}`}  onMouseEnter={() => {if (comp.type !== 'both') setHighlight(comp.type)}} onMouseLeave={onMouseExit}>
								{Array.isArray(comp.text) ? comp.text.map(st => {return <p>{st}</p>}) : comp.text}
							</div>
						)}
						{ gameRules.highLow && <small>You may go both High & Low{numCards > 5 ? ' with two different hands' : ''}.<br/>If you declare Both, you must win both High & Low.</small> }
					</h6>
				}
				<hr/>
				{ gameRules.rules && <><ul>{ gameRules.rules.map(rule => <li>{rule}</li>) } </ul><hr/></>}
				{ rounds(gameRules) }
			</Modal.Body>
		</Modal>
	);
}