import React from 'react';

import './Pot.scss';

import ChipStack from './ChipStack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight as sidePotIcon } from '@fortawesome/free-solid-svg-icons'

const potAmount = (pot) => {
  return pot.committed || pot.committed === 0 ? pot.committed : pot.amount;
}

const Pot = (props) => {
  // const AnimatedPot = props.keyframes;
  return 	(
    // <AnimatedPot native state={props.animationState}>
    //   {style => (
    //     <div className="px-2 badge badge-success">$<animated.span>{style.number.interpolate(number => Math.ceil(number))}</animated.span></div>
    //   )}
    // </AnimatedPot>
    <ChipStack containerClass="pot" amount={potAmount(props.pot)} />
  );
}

const SidePot = (props) => {
  const { sidePot } = props;

  // const players = sidePot.players.reduce((st, player) => {
  //   return st += Object.values(table).find(p => p.id === player).name + '\n';
  // }, '');

  return (
    <ChipStack containerClass="side-pot" amount={potAmount(sidePot)} />
  );
}

const PotContainer = (props) => {

  // const capturePosition = rect => {
  //   props.potPositionHandler(rect.x, rect.y);
  // }

  // const potKeyframes = Keyframes.Spring({
  //   initial: { number: props.potAmounts[0] },
  //   increment: {
  //     delay: 650,
  //     from: { number: props.potAmounts[0] },
  //     to: { number: props.potAmounts[0] + props.potIncrementAmounts[0] },
  //     config: { mass: 1, tension: 280, friction: 80 }
  //   }
  // });

  var { pot, playerId, table } = props;
  return 	(
    <div className="pot-container">
      {/* <Pot animationState={props.increment ? 'increment' : 'initial' } keyframes={potKeyframes}></Pot> */}
      <Pot pot={pot}/>
      { pot.sidePots && <>
        <FontAwesomeIcon className="side-pot-icon" icon={sidePotIcon} inverse/>
        <div className="side-pot-container">
          { pot.sidePots.map((sidePot, i) =>
            <SidePot key={i} sidePot={sidePot} playerId={playerId} table={table}/>
          )}
        </div>
      </>}
      {/* <ResizeObserver onPosition={capturePosition} /> */}
    </div>
  );
}

export { Pot, PotContainer };