import React from 'react';

import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExchangeAlt as exchange,
} from '@fortawesome/free-solid-svg-icons'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

export default function DiscardBadge(props) {
	let { discarded } = props;

	return (
		<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>discarded {discarded} card{discarded > 1 ? 's' : ''}</Tooltip>} >
			<Badge variant="info" className="discard">
				<FontAwesomeIcon icon={exchange} size="2x" />
				{ discarded || 3}
			</Badge>
		</OverlayTrigger>
	)
}
