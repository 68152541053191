import React from 'react';

import { Badge } from 'react-bootstrap';
import { CardCollection } from '../deck/CardCollection';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import './GameRules.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUser as userIcon,
	faArrowCircleUp as highIcon,
	faArrowCircleDown as lowIcon,
	faExchangeAlt as exchange,
	faHandPointLeft as push,
	faHandPointDown as keep
 } from '@fortawesome/free-solid-svg-icons'

const player = (seat, layout, cards, style, facedown) => {
	return <div className={`player player${seat}`} style={style}>
		<FontAwesomeIcon className="mx-1" icon={userIcon} inverse/>
		<CardCollection layout={layout} cards={cards} masked={true} isBoard={facedown}/>
	</div>
}

export default function GameRulesTable(props) {
	const onMouseExit = () => {
		setHighlight(null);
	}

	var { gameRules, highlight, setHighlight } = props;
	return (
		<div className="table" style={gameRules.table ? gameRules.table.style : null}>
			<div className="table-background">
				{ gameRules.players.map((p, i) => player(i + 1, p.layout, p[highlight] ? p[highlight] : p.cards, p.style, p.facedown)) }
				{ gameRules.board && <div className="community-cards-outer" style={gameRules.board ? gameRules.board.style : null}>
					<div className="community-cards-inner">
						<div className="community-cards">
							<CardCollection layout={gameRules.board.layout} isBoard={true} cards={gameRules.board[highlight] ? gameRules.board[highlight] : gameRules.board.cards} />
						</div>
					</div>
				</div> }
			</div>
			<div className="icons">
				{ gameRules.push && <OverlayTrigger placement="top" overlay={<Tooltip>Cards may be pushed to opponent.</Tooltip>} >
					<Badge className="mx-1" variant="info"><FontAwesomeIcon icon={push} /></Badge>
				</OverlayTrigger> }
				{ gameRules.keep && <OverlayTrigger placement="top" overlay={<Tooltip>Cards may be chosen from the board.</Tooltip>} >
					<Badge className="mx-1" variant="info"><FontAwesomeIcon icon={keep} /></Badge>
				</OverlayTrigger> }
				{ gameRules.exchange && <OverlayTrigger placement="top" overlay={<Tooltip>Cards may be exchanged.</Tooltip>} >
					<Badge className="mx-1" variant="info"><FontAwesomeIcon icon={exchange} /></Badge>
				</OverlayTrigger> }
				<OverlayTrigger placement="top" overlay={<Tooltip>Best high hand wins. Example highlighted.</Tooltip>} >
					<FontAwesomeIcon className={`mx-1 ${highlight === 'highHand' ? 'highlight' : ''}`} icon={highIcon} inverse onMouseEnter={() => setHighlight('highHand')} onMouseLeave={onMouseExit}/>
				</OverlayTrigger>
				{ gameRules.highLow && <OverlayTrigger placement="top" overlay={<Tooltip>Best low hand wins. Example highlighted.</Tooltip>} >
					<FontAwesomeIcon className={`mx-1 ${highlight === 'lowHand' ? 'highlight' : ''}`} icon={lowIcon} onMouseEnter={() => setHighlight('lowHand')} onMouseLeave={onMouseExit}/>
				</OverlayTrigger> }
				{ gameRules.highLow && props.lowVariant === '5-4' && <OverlayTrigger placement="top" overlay={<Tooltip>Straights and flushes count for low hand. Best low hand is A-2-3-4-5.</Tooltip>} >
					<Badge className="mx-1 low-variant" variant="info">5-4</Badge>
				</OverlayTrigger> }
				{ gameRules.highLow && props.lowVariant === '6-4' && <OverlayTrigger placement="top" overlay={<Tooltip>Straights and flushes do NOT count for low hand. Best low hand is A-2-3-4-6.</Tooltip>} >
					<Badge className="mx-1 low-variant" variant="info">6-4</Badge>
				</OverlayTrigger> }
			</div>
		</div>
	);
}