import React from 'react';

import { Button, ButtonGroup, ToggleButton, Dropdown } from 'react-bootstrap';

import {
	faRunning as toggleIcon,
	faUserClock as currentIcon,
	faExternalLinkAlt as launchIcon,
	faPlay as runIcon,
	faUserPlus as inviteIcon,
	faPlug as connectIcon,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ServerTime } from '../ServerTime'

import { useMutation, gql } from '@apollo/client';
import { useClock } from '@circleleague/ui/';

import './Harness.css'

let styles = {
	controls: {
		// height: '100vh',

		padding: 10,

		display: 'flex',
		flexDirection: 'column',

		// backgroundColor: 'red'
	},
	
	control: {
		marginLeft: 10,
		marginTop: 5,
		// marginBottom: 5,
		
		color: 'white',
		pointerEvents: 'auto'
	}
}

const RUN_SCENARIO = gql`
	mutation RunScenario($id: ID!) {
		runScenario(id: $id) {
			id
			host {
				id
			}
			joinLink
		}
	}
`;

const INVITE_PLAYER = gql`
	mutation InvitePlayer($email: String!, $sessionId: ID!) {
		invite(email: $email, sessionId: $sessionId) {
			id
		}
	}
`;

const ServerClock = React.createContext({
	currentTime: Date.now()
})

function Clock() {
	let serverTime = React.useContext(ServerTime);
	let { currentTime, setCurrentTime } = useClock(Date.now());

	React.useEffect(z => setCurrentTime(serverTime.currentTime()), [serverTime.lastServerTs]);
	
	return <h5>{new Date(currentTime).toLocaleTimeString()}</h5>
}

function SessionScenarios(props) {
	let [runScenario, runScenarioResponse] = useMutation(RUN_SCENARIO, {
		client: props.ecosystem.guestApi
  	});
	React.useEffect(z => {
		let { called, loading, data } = runScenarioResponse;

		if (called && !loading) {
			window.location = data.runScenario.joinLink;
		}
	})

	function submit() {
		return runScenario({
			variables: {
				id: '100'
			}
		});
	}

	return (
	    <Button onClick={submit} style={styles.control}>
		  <FontAwesomeIcon icon={runIcon} size="sm" />
   	    </Button>
	)
}

function CurrentPlayer(props) {
	let { game, session } = props;

	let currentAction = game.sequence.actionOn;

	function launch() {
		let currentPlayerId = currentAction[0];

		let player = session.players[currentPlayerId];

		window.location = player.joinLink;
	}

	return (
	    <Button style={styles.control} onClick={launch} variant="info" disabled={!currentAction || currentAction.length === 0}>
		  <FontAwesomeIcon icon={currentIcon} size="sm" />
   	    </Button>
	)
}

function PlayerLauncher(props) {
	let { session, onPlayerSelected, buttonIcon } = props;

	let players = session ? session.players : {};
	let playerIds = Object.keys(players);

	return (
      <Dropdown style={styles.control}>
        <Dropdown.Toggle variant="success" size="sm">
		  <FontAwesomeIcon icon={buttonIcon} size="sm" inverse />
        </Dropdown.Toggle>

        <Dropdown.Menu>
		  { playerIds.map(p => <Dropdown.Item key={p} href="#" onClick={z => onPlayerSelected(players[p])}>{players[p].metadata.name}</Dropdown.Item>) }
        </Dropdown.Menu>
      </Dropdown>
	)
}

function InvitePlayer(props) {
	let { session } = props;

	let [invitePlayer] = useMutation(INVITE_PLAYER, {
		client: props.ecosystem.playerApi
  	});

	function invite() {
		return invitePlayer({
			variables: {
				email: 'player@test.com',
				sessionId: session.id
			}
		});
	}

	return (
	    <Button onClick={invite} style={styles.control} variant="info">
		  <FontAwesomeIcon icon={inviteIcon} size="sm" />
   	    </Button>
	)
}

function HarnessActions(props) {
	let { session, game, ws } = props;

	function toggleConnection() {
		if (ws.readyState === 1) return ws.close();

		ws.reconnect();
	}

	function togglePlayer(player) {
		window.location = player.joinLink;
	}

	function launchPlayer(player) {
		window.open(player.joinLink, '_blank');
	}

	return (
	  <div className="d-flex flex-column">
		<div className="mb-2">
	      { !session && <SessionScenarios {...props} /> }
		</div>

		<div className="mb-2">
	      { game && <CurrentPlayer {...props} /> }
		</div>

		<div className="mb-2">
	      { session && <PlayerLauncher buttonIcon={toggleIcon} session={session} onPlayerSelected={togglePlayer} /> }
		</div>

		<div className="mb-2">
	      { session && <PlayerLauncher buttonIcon={launchIcon} session={session} onPlayerSelected={launchPlayer} /> }
		</div>

		<div className="mb-2">
	      { session && <InvitePlayer  {...props} /> }
		</div>

		<div>
		  <ButtonGroup toggle className="mt-1" style={styles.control}>
            <ToggleButton type="checkbox" checked={ws.readyState === 1} value="1"
			    onChange={(e) => toggleConnection(e.currentTarget.checked)}>
              <FontAwesomeIcon icon={connectIcon} size="sm" />
            </ToggleButton>
          </ButtonGroup>
		</div>
	  </div>
	)
}

export default function HarnessLayer(props) {
	return (
		<div className="harness-layer">
		  <div style={styles.controls}>
			<Clock />
			<HarnessActions {...props} />
		  </div>
		</div>
	)
}
