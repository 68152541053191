import React from 'react';

import { Button } from 'react-bootstrap';
import {
	faQuestionCircle as unknownIcon,
	faCheckCircle as availableIcon,
	faExclamationTriangle as unavailableIcon,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DetectRTC from 'detectrtc';

class CompatibilityChecklist extends React.Component {

	MIN_WIDTH = 600;
	MIN_HEIGHT = 600;

	UNKNOWN = 1001;
	AVAILABLE = 1002;
	UNAVAILABLE = 1003;

	iconMap = {
		1001: <FontAwesomeIcon className="text-info" icon={unknownIcon} size="2x" />,
		1002: <FontAwesomeIcon className="text-success" icon={availableIcon} size="2x" />,
		1003: <FontAwesomeIcon className="text-warning" icon={unavailableIcon} size="2x" />
	}

	unknownIcon = 'unknown'
	availableIcon = 'available'
	unavailableIcon = 'unavailable'
	warningIcon = 'warning'

	checkRunning = false;

	pauseLength = 200

	state = {
		compatibilityPassed: false,
		checks: [],
	}

	buildChecks() {
		return [{
			name: 'browser',
			icon: undefined,
			label: 'Browser compatibility',
			status: this.UNKNOWN,
			f: this.checkBrowser.bind(this)
		}, {
			name: 'screen',
			icon: undefined,
			label: 'Screen size',
			status: this.UNKNOWN,
			f: this.checkScreen.bind(this)
		}, {
			name: 'av',
			icon: undefined,
			label: 'Permission to use devices',
			status: this.UNKNOWN,
			f: this.checkAudioVideoPermissions.bind(this)
		}, {
			name: 'video',
			icon: undefined,
			label: 'Video available',
			status: this.UNKNOWN,
			f: this.checkVideo.bind(this)
		}, {
			name: 'speakers',
			icon: undefined,
			label: 'Speakers available',
			status: this.UNKNOWN,
			f: this.checkSpeakers.bind(this)
		}, {
			name: 'microphone',
			icon: undefined,
			label: 'Microphone available',
			status: this.UNKNOWN,
			f: this.checkMicrophone.bind(this)
		}, {
			name: 'settings',
			icon: undefined,
			label: 'Load settings',
			status: this.UNKNOWN,
			f: this.loadSettings.bind(this)
		}, {
			name: 'selectVideo',
			icon: undefined,
			label: 'Selecting video',
			status: this.UNKNOWN,
			f: this.selectVideo.bind(this)
		}, {
			name: 'selectSpeaker',
			icon: undefined,
			label: 'Selecting speaker',
			status: this.UNKNOWN,
			f: this.selectSpeaker.bind(this)
		}, {
			name: 'selectMicrophone',
			icon: undefined,
			label: 'Selecting microphone',
			status: this.UNKNOWN,
			f: this.selectMicrophone.bind(this)
		}, {
			name: 'connect',
			icon: undefined,
			label: 'Testing connection',
			status: this.UNKNOWN,
			f: this.connect.bind(this)
		}]
	}

	detect() {
		return new Promise(resolve => {
			DetectRTC.load(z => resolve(DetectRTC));
		})
	}

	checkBrowser() {
		// console.log(DetectRTC.browser.name);
		// console.log(DetectRTC.browser.version);
		// DetectRTC.osName
		// DetectRTC.osVersion
		// DetectRTC.browser.name === 'Edge' || 'Chrome' || 'Firefox'
		// DetectRTC.browser.version
		// DetectRTC.browser.isChrome
		// DetectRTC.browser.isFirefox
		// DetectRTC.browser.isOpera
		// DetectRTC.browser.isIE
		// DetectRTC.browser.isSafari
		// DetectRTC.browser.isEdge
		// DetectRTC.checkWebSocketsSupport(callback)
		var passing = DetectRTC.isWebRTCSupported
			&& DetectRTC.isWebSocketsSupported
			&& !DetectRTC.isWebSocketsBlocked
			&& !DetectRTC.isMobileDevice
			;

		return Promise.resolve(passing)
	}

	checkScreen() {
		// console.log(`width: ${window.innerWidth}`);
		// console.log(`height: ${window.innerHeight}`);

		var result = `${window.innerWidth}x${window.innerHeight}`

		if (window.innerWidth >= this.MIN_WIDTH && window.innerHeight >= this.MIN_HEIGHT)
			return Promise.resolve(result)

		return Promise.reject(result);
	}

	checkAudioVideoPermissions() {
		return navigator.mediaDevices.getUserMedia({
				audio: true,
				video: {
					aspectRatio: { ideal: 1.7777777778 }
				}
			})
			.then(stream => {
				stream.getTracks().forEach(track => track.stop());
			})
			.then(z => {
				// reload...
				return new Promise(resolve => DetectRTC.load(z => resolve(DetectRTC)))
					.then(z => true)
					;
			})
			.catch(e => {
				throw new Error('access to devices is denied');
			})
			;
	}

	checkVideo() {
		return new Promise((resolve, reject) => {
			if (!DetectRTC.hasWebcam) reject('no camera found');
			if (!DetectRTC.isWebsiteHasWebcamPermissions) reject('no permission to use camera');
			if (DetectRTC.videoInputDevices.length > 0) return resolve(true);
			reject('could not connect to camera');
		})
	}
	
	checkSpeakers() {
		return new Promise((resolve, reject) => {
			if (!DetectRTC.hasSpeakers) reject('no audio output found');
			if (DetectRTC.audioOutputDevices.length > 0) return resolve(true);
			reject('could not connect to audio output');
		})
	}

	checkMicrophone() {
		return new Promise((resolve, reject) => {
			if (!DetectRTC.hasMicrophone) reject('no microphone found');
			if (!DetectRTC.isWebsiteHasMicrophonePermissions) reject('no permission to use microphone');
			if (DetectRTC.audioInputDevices.length > 0) return resolve(true);
			reject('could not connect to microphone');
		})
	}

	loadSettings() {
		return Promise.resolve(true);
	}
	
	selectVideo() {
		return Promise.resolve(true);
	}

	selectSpeaker() {
		return Promise.resolve(true);
	}

	selectMicrophone() {
		return Promise.resolve(true);
	}
	
	/**
	 * TODO: this check really isn't doing anything as you couldnt get here probably without
	 * connecting to the server first
	 */
	connect() {
		return Promise.resolve(true);
	}

	pauseForDramaticEffect() {
		return new Promise(resolve => {
			this.timeoutInterval = setTimeout(resolve, this.pauseLength);
		})
	}

	componentDidMount() {
		this.checkRunning = true;

		var checks = this.buildChecks();

		var _p = this.detect()
			.then(z => this.setState({ checks: checks }))
			;

		checks.forEach(c => {
			_p = _p.then(z => {
				if (this.checkRunning) {
					return this.pauseForDramaticEffect()
						.then(z => console.debug(`checking ${c.name}`))
						.then(c.f)
						.then(result => {
							c.result = result;
							c.status = this.AVAILABLE;
						})
						.catch(error => {
							c.result = error.toString();
							c.status = this.UNAVAILABLE;
						})
						.then(z => this.setState({ checks: checks }))
						;
				}
				
				return clearInterval(this.timeoutInterval);
			})
		})

		_p.then(z => {
			this.checkRunning = false;

			console.debug('all checks complete');

			this.setState({ compatibilityPassed: true })
		});
	}

	componentWillUnmount() {
		this.checkRunning = false;

		if (this.timeoutInterval) clearInterval(this.timeoutInterval);
	}

	render() {
		var { checks, compatibilityPassed } = this.state;
		var { handleLaunch } = this.props;

		return (
			<div className="checklist w-100">
			  <div className="w-100 d-flex flex-column justify-content-center">
				{ checks.map(c => {
					return (
					  <div key={c.name} className="mb-2 d-flex justify-content-center align-items-end">
						<div className="mr-3">
						  { this.iconMap[c.status] }
						</div>
						<div className="h-100 mr-2 mb-0 h5 text-uppercase font-weight-bold">{c.label}</div>
						<div className="mr-2 text-muted">{c.result}</div>
					  </div>
					)
				})}
			  </div>
			  <div className="mt-3 d-flex justify-content-center">
			    <Button disabled={!compatibilityPassed || !handleLaunch} onClick={handleLaunch}>Launch!</Button>
			  </div>
			</div>
		)
	}

}

export default CompatibilityChecklist;
