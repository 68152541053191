import React from 'react';

import { Button } from 'react-bootstrap';

class Support extends React.Component {

	render() {
		var { error } = this.props;

		var message = error ? error.message || '' : ''

		return (
			<div className="App d-flex flex-column justify-content-center align-items-center">
			  <h1>Sorry!  Please contact our (non-existent) support</h1>
			  
			  <div className="text-muted">{ message }</div>

			  <h5 className="mt-5 mb-3">try reloading your browser</h5>
			  <Button className="text-uppercase" variant="success" onClick={z => window.location.reload()}>Reload</Button>
			</div>
		)		
	}

}

export default Support;