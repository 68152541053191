import React from 'react';

import { Button, Image } from 'react-bootstrap';

import Me from '../me/Me';
import PlayerTile from '../player/PlayerTile'
import { CardCollection, flagCardsInBestHand } from '../deck/CardCollection'
import { PotContainer } from '../table/Pot'
import GameCarousel from '../table/GameCarousel';
import GameRulesDialog from './GameRulesDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as infoIcon } from '@fortawesome/free-solid-svg-icons'

import '../meeting/Video.css'
import './CommunityTile.css'
import './LayoutTenVideoTiles.scss'

import env from '../env';

const IMAGES = {
	main: env.imageUrl("/deck/honor_clubs.png"),
}

function Table(props) {
	let { session, hand, audioVideo, wssStatus } = props;

	let { seats } = session.table;
	let numFilledSeats = seats.filter(seat => seat.player).length;

	let me = session.me || {};

	let clock = hand ? hand.clock : undefined;
	let sequence = hand ? hand.sequence : undefined;
	let isMuckSequence = sequence && sequence.name === 'muck';
	let cardLayout = hand && hand.game && hand.game.playerLayout ? hand.game.playerLayout : [];

	let [boardSelection, setBoardSelection] = React.useState([]);
	let [showGameRulesDialog, setShowGameRulesDialog] = React.useState(false);
	let [declarationToggle, setDeclarationToggle] = React.useState();
	let [bestHandPlayerId, setBestHandPlayerId] = React.useState();

	function handleBestHandHover(declaration, playerId) {
		if (!isMuckSequence) return;

		setDeclarationToggle(declaration);
		setBestHandPlayerId(playerId);
	}

	let bestHand, bestHandPlayer, showBestDeclaration;
	if (isMuckSequence) {
		// console.debug('$$$$$$$$$$', declarationToggle, bestHandPlayerId);

		if (declarationToggle) {
			bestHandPlayer = hand.table[bestHandPlayerId];

			if (bestHandPlayer.best) {
				bestHand = bestHandPlayer.best[declarationToggle];
				showBestDeclaration = declarationToggle;
			}
		}

		if (!bestHand) {
			let winners = Object.values(hand.table).filter(player => player.award > 0);
			bestHandPlayer = winners.reduce((best, player) => {
				if (best) return best.award > player.award ? best : player;

				return best;
			})

			// NOTE: if everyone folded, winner will not have best hand properties
			if (bestHandPlayer.best) {
				if (bestHandPlayer.best.high) {
					bestHand = bestHandPlayer.best.high;
	
					showBestDeclaration = 'high';
				} else if (bestHandPlayer.best.low) {
					bestHand = bestHandPlayer.best.low;
					showBestDeclaration = 'low';
				} else {
					bestHand = bestHandPlayer.best;
					showBestDeclaration = 'high';
				}
			}
		}
	}

	let tableContent = null;

	if (hand) {
		let showPot = hand.table && Object.values(hand.table).findIndex(player => player.award > 0) < 0;
		let pot = hand.pot || {};
		let community = hand.communityCards || [];

		let sequence = hand ? hand.sequence : undefined;
		let isChooseSequence = sequence && sequence.name === 'choose' && hand && hand.actions.choose;
	
		let cards = bestHand && community.length > 0 ? flagCardsInBestHand(community, bestHand, bestHandPlayer.award > 0) : community;

		tableContent = <div className={`seats-${numFilledSeats} community-cards-outer`}>
			<div className="community-cards-inner">
				{ hand.game.boardLayout && <div className="community-cards">
					<CardCollection cards={cards}
						layout={hand.game.boardLayout}
						isBoard={true}
						maxSelect={isChooseSequence ? 1 : 0}
						selectedCards={boardSelection}
						selectionChanged={isChooseSequence ? setBoardSelection : undefined}
						cardSize={{maxWidth: hand.game.boardLayout.length < 3 ? '7vmin' : '6vmin'}}
					/>
				</div> }
				{/* <PotContainer potPositionHandler={potPositionHandler} potAmounts={[pot.amount]} potIncrementAmounts={[100]} increment={rake}/> */}
				{ showPot && <PotContainer pot={pot} playerId={hand.me ? hand.me.id : undefined } table={hand.table}/> }
			</div>
			<div className="table-text">
				<Button variant="link" onClick={z => setShowGameRulesDialog(true)}>{session.table.game ? session.table.game.title : ''}</Button>
				<FontAwesomeIcon className="info-icon" icon={infoIcon} size="sm" onClick={z => setShowGameRulesDialog(true)}/>
				{ session.table.game && <GameRulesDialog game={session.table.game} show={showGameRulesDialog} onHide={z => setShowGameRulesDialog(false)}/> }
			</div>
		</div>;
	}
	else if (session.actions && session.actions.start) {
		let startAction = session.actions.start;
		let games = startAction.game.choice;

		tableContent = <div className="board-content">
			<GameCarousel games={games} onPlay={game => session.action('start', { game }) }/>
		</div>;
	}
	else if (session.actions) {
		let message = session.actions.end ? 'waiting for players to join the table...' : 'waiting for next hand to start'
		tableContent = <div className="board-content">
			<div className="mx-auto mb-2"><Image src={IMAGES.main} width={200} /></div>
			<div className="d-flex justify-content-center mx-auto py-2">
				<h5 className="text-muted">{message}</h5>
			</div>
		</div>;
	}

	const playerRenderer = seat => {
		if (!seat || !seat.player) return;  // render nothing if no player at that seat

		let playerHand = hand && hand.table ? (hand.table[seat.player.id] || {}) : {};

		return <PlayerTile seat={seat} player={playerHand} layout={cardLayout} 
				clock={clock} sequence={sequence} audioVideo={audioVideo}
				showBest={bestHandPlayer && playerHand.id === bestHandPlayer.id ? showBestDeclaration : undefined}
				handleBestHandHover={handleBestHandHover} />
	};

	const i = seats.findIndex(player => player.player && player.player.id === me.id);
	const rotatedPlayers = seats.slice(i).concat(seats.slice(0, i));

	let opponents = rotatedPlayers.filter(player => {
		return player.player && player.player.id !== me.id;
	});

	let cardLayoutLength = cardLayout ? Math.max(cardLayout.map(l => l.length)) : 0;

	return (
		<>
			<div className="table">
				<div className={`seats-${numFilledSeats} table-background`}>
					{
						opponents.map((seat, index) => {
							return (
								<div key={seat.player.id} className={`player-tile-layout seats-${opponents.length + 1} seat-${index+1} layout-${cardLayoutLength}`}>
									{ playerRenderer(seat) }
								</div>
							)
						})
					}
				</div>
				{ tableContent }
			</div>
			{ session && session.me && <Me session={session} hand={hand}
				audioVideo={audioVideo} wssStatus={wssStatus}
				boardSelection={boardSelection}
				showBest={bestHandPlayer && session.me.id === bestHandPlayer.id ? showBestDeclaration : undefined}
				handleBestHandHover={handleBestHandHover}/> }
		</>
	);
}

export default Table;