import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { AudioInputMeter } from './AudioInputMeter';
import { VideoPreview } from '@circleleague/ui';
import { SettingsContext } from '../Settings'
import { Sound } from '@circleleague/ui';

import './Devices.css'

class DeviceSelector extends React.Component {

	render() {
		var { id, label, devices, setting, onChangeDevice } = this.props;

		return (
			<div className="select-device-container offset-md-2 col-md-10">
			  <label htmlFor={id}>{label}</label>

			  <SettingsContext.Consumer>
				{ settings => {
					var selectedDevice = settings.audioVideo[setting];

					// console.log(`${setting}: ${selectedDevice}`);

					return (
			            <select id={id} className="custom-select"
			    			value={selectedDevice || ''}
			    			onChange={ event => onChangeDevice(event.target.value, settings) }>
			    			{
				  			  devices.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)
			    			}
			  			</select>
					)
				}}
			  </SettingsContext.Consumer>
		    </div>
	    )
	}

}

function SpeakerTest() {
	let [source, setSource] = React.useState();
	let [trigger, setTrigger] = React.useState();

	function testSpeaker() {
		setSource('/shuffle.wav');
		setTrigger(Date.now());
	}

	return (
		<>
		  <Button variant="info" onClick={testSpeaker}>Test Speaker</Button>
		  { source && <Sound source={source} trigger={trigger} /> }
		</>
	)
}

class Devices extends React.Component {

	state = {
		audioInputDevices: [],
		audioOutputDevices: [],
		videoInputDevices: [],
	}

	listenForDeviceChanges() {
		var { audioVideo } = this.props;

    	audioVideo.addDeviceChangeObserver({
			audioInputsChanged: devices => this.devicesDetected(devices, 'audioInputDevices', 'audioInputDevice', 'chooseAudioInputDevice'),
			audioOutputsChanged: devices => this.devicesDetected(devices, 'audioOutputDevices', 'audioOutputDevice', 'chooseAudioOutputDevice'),
			videoInputsChanged: devices => this.devicesDetected(devices, 'videoInputDevices', 'videoInputDevice', 'chooseVideoInputDevice'),
		});
	}

	devicesDetected(devices, stateProperty, setting, chooseF) {
		var { settings, audioVideo } = this.props;

		var newState = {}
		newState[stateProperty] = devices;

		var currentSelection = settings.audioVideo[setting];

		var avail = devices.find(d => d.deviceId === currentSelection);

		var deviceId = avail ? currentSelection : devices[0].deviceId;
		var _p = avail ? Promise.resolve() : audioVideo[chooseF](deviceId);

		return _p
			.then(z => settings.audioVideo[setting] = deviceId)
			.then(z => this.setState(newState));
	}

	changeAudioInputDevice(deviceId, settings) {
		var { audioVideo } = this.props;

		audioVideo.chooseAudioInputDevice(deviceId)
			.then(z => settings.audioVideo.audioInputDevice = deviceId)
			.then(z => this.setState({ settingsUpdated: Date.now() }));
	}
	
	changeAudioOutputDevice(deviceId, settings) {
		var { audioVideo } = this.props;


		audioVideo.chooseAudioOutputDevice(deviceId)
			.then(z => settings.audioVideo.audioOutputDevice = deviceId)
			.then(z => this.setState({ settingsUpdated: Date.now() }));
			;
	}
	
	changeVideoInputDevice(deviceId, settings) {
		var { audioVideo } = this.props;
	
		audioVideo.chooseVideoInputDevice(deviceId)
			.then(z => settings.audioVideo.videoInputDevice = deviceId)
			.then(z => this.setState({ settingsUpdated: Date.now() }));
			;
	}

	async componentDidMount() {
		this.listenForDeviceChanges();

		var { audioVideo } = this.props;

		const audioInputDevices = await audioVideo.listAudioInputDevices();
		const audioOutputDevices = await audioVideo.listAudioOutputDevices();
		const videoInputDevices = await audioVideo.listVideoInputDevices();

		this.setState({
			audioInputDevices: audioInputDevices,
			audioOutputDevices: audioOutputDevices,
			videoInputDevices: videoInputDevices,
		});
	}

	render() {
		var { show, onHide, audioVideo } = this.props;
		var { audioInputDevices, audioOutputDevices, videoInputDevices } = this.state;

        return (
		<Modal show={show} onHide={onHide} dialogClassName="container">
          <Modal.Header closeButton>
            <Modal.Title>Select Audio/Video Devices</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
            <div className="col-md-6">
              <div className="row mt-3">
			    <DeviceSelector id="audio-input" label="Microphone"
					devices={audioInputDevices || []}
					setting="audioInputDevice"
					onChangeDevice={(deviceId, settings) => this.changeAudioInputDevice(deviceId, settings)}
					/>
              </div>
              <div className="row mt-3">
				<DeviceSelector id="audio-output" label="Speaker"
					devices={audioOutputDevices || []}
					setting="audioOutputDevice"
					onChangeDevice={(deviceId, settings) => this.changeAudioOutputDevice(deviceId, settings)}
					/>
              </div>
              <div className="row mt-3">
				<DeviceSelector id="video-input" label="Camera" disabled={!this.props.showVideoPreview}
					devices={videoInputDevices || []}
					setting="videoInputDevice"
					onChangeDevice={(deviceId, settings) => this.changeVideoInputDevice(deviceId, settings)}
					/>
              </div>
            </div>
            <div className="offset-md-1 col-md-3">
              <div className="row mt-3 d-flex flex-column">
                <label>Preview</label>
				<AudioInputMeter audioVideo={audioVideo} />
				<div className="mt-2">
			      <VideoPreview audioVideo={audioVideo} show={true} />
				</div>
				<div className="mt-2">
				  <SpeakerTest />
				</div>
              </div>
            </div>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
	}
}

export default Devices;