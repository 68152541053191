import React from 'react';
import { SelectableCard } from './SelectableCard'
import Placeholder from './Placeholder'

import './CardCollection.css';

const DEFAULT_MAX_SELECT = 3;

function CardCollection(props) {
	let cards = props.cards || [];
	let { layout, isBoard, selectionChanged, masked } = props;
	let maxSelect = props.maxSelect || DEFAULT_MAX_SELECT;
	
	let selectedCards = props.selectedCards || [];
	
	// whether or not any card can be selected
	let allowSelection = selectionChanged && (selectedCards.length < maxSelect || maxSelect === 1);

	function cardClick(card) {
		if (selectionChanged) {
			/**
			 * NOTE: single card select/deselect is supported here; in the case where only one
			 * selection is allowed, you are able to change your selection with a single click
			 */
			if (maxSelect > 1) {
				let newSelection = [];
				let isSelected = false;
				
				selectedCards.forEach(c => {
					if (c.id === card.id) isSelected = true;
					else newSelection.push(c);
				})
				
				if (!isSelected) newSelection.push(card);
				
				selectionChanged(newSelection);
			}
			else {
				let deselect = selectedCards.length > 0 && (card && card.id === selectedCards[0].id);
				selectionChanged(deselect ? [] : [card]);
			}
		}
	}

	let arranged = [];
	if (layout) {
		layout.forEach(row => {
			let r = [];
			row.forEach(col => {
				if (col < 0) r.push('empty')
				else if (col >= cards.length) r.push(isBoard ? 'facedown' : 'outline')
				else if (col < cards.length) r.push(cards[col]);
			})
			
			arranged.push(r);
		})
	} else arranged.push(cards); // row of 1
	
	return (
		<div className="CardCollection">
		{
			arranged.map((row, rowIndex) => (
				<div className="CardRow" key={rowIndex}>
					{
				      row.map((card, i) => {
								if (card === 'empty') return <Placeholder key={i} cardSize={props.cardSize} />;
								if (card === 'outline') return <Placeholder type="outline" key={i} cardSize={props.cardSize} />;
								return <SelectableCard card={card}
									key={i}
									defaultClass={props.defaultClass}
									cardSize={props.cardSize}
									flipped={props.flipped}
									masked={masked && card.concealed}
									selectable={allowSelection && card.id}
									selected={selectedCards.some(c => c.id === card.id)}
									onClick={cardClick}
								/>
							}
					  )
					}
				</div>
			))
		}
		</div>
	)
}

const flagCardsInBestHand = (cards, bestHand, winningHand) => {
	if (!Array.isArray(bestHand)) return cards;
	return cards.map(card => {
		if (!card.id) return {};
		return { id: card.id, inBestHand: bestHand && bestHand.find(best => best.id === card.id), inWinningHand: winningHand };
	});
}

export { CardCollection, flagCardsInBestHand };