import { CardCollection, flagCardsInBestHand } from '../deck/CardCollection'

export default function MyCards(props) {
	let { hand, showBest, selectedCards, onSelectionChanged } = props;

	let player = hand.me;
	let { cards } = player;
	
	if (showBest) {
		let bestHand = player.best[showBest] || player.best;
		cards = flagCardsInBestHand(cards, bestHand, player.award > 0);
	}

	if (hand.actions.push) cards[cards.length - 1].pushKeep = true;

	let hasExchange = hand.actions && hand.actions.exchange;
	
	let f = hasExchange ? onSelectionChanged : null;
	
	return (
		<CardCollection
			layout={hand.game.playerLayout}
			masked={player.live && hand.game.maskCards}
			cards={cards}
			maxSelect={hasExchange ? hand.actions.exchange.max : null}
			defaultClass={player.live ? "" : "PlayingCardFolded"}
			selectedCards={selectedCards}
			selectionChanged={f}
			/>
	)
}