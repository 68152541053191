import React from 'react'

class AudioElement extends React.Component {

	componentDidMount() {
		this.props.audioVideo.bindAudioElement(this.audioElement);
	}

	render() {
		return ( <audio ref={ el => this.audioElement = el }/> )
	}
  
}

class AudioInputMeter extends React.Component {

	analyserNodeCallback = z => {}
  
	setAudioPreviewPercent(percent) {
        var { audioPreviewElement: el } = this;

		if (!el) return;
		  
	  	if (el.getAttribute('aria-valuenow') !== `${percent}`) {
			el.style.width = `${percent}%`;
			el.setAttribute('aria-valuenow', `${percent}`);
		}
		  
	  	const transitionDuration = '33ms';
	  	if (el.style.transitionDuration !== transitionDuration) {
			el.style.transitionDuration = transitionDuration;
	  	}
	}
	
	async startAudioPreview() {
		var { audioPreviewElement: el } = this;

	  	if (!el) return;
  
		this.setAudioPreviewPercent(0);
		  
	  	const analyserNode = this.props.audioVideo.createAnalyserNodeForAudioInput();
	  	if (!analyserNode) return;
	  	if (!analyserNode.getByteTimeDomainData) {
			el.parentElement.style.visibility = 'hidden';
			return;
		}

	  	const data = new Uint8Array(analyserNode.fftSize);
	  	let frameIndex = 0;
	  	this.analyserNodeCallback = z => {
			if (frameIndex === 0) {
				analyserNode.getByteTimeDomainData(data);
				const lowest = 0.01;
				let max = lowest;
				for (const f of data) max = Math.max(max, (f - 128) / 128);
				let normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
				let percent = Math.min(Math.max(normalized * 100, 0), 100);
				this.setAudioPreviewPercent(percent);
			}

			frameIndex = (frameIndex + 1) % 2;

			requestAnimationFrame(this.analyserNodeCallback);
		};
		  
	  	requestAnimationFrame(this.analyserNodeCallback);
	}
  
	componentDidMount() {
		this.startAudioPreview();
	}

	componentDidUpdate() {
		this.startAudioPreview();
	}

	render() {
		return (
			<div className="progress">
			  <div className="progress-bar bg-success" ref={el => this.audioPreviewElement = el} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		)
		/*
		return (
			<div className="progress" style={{marginBottom: '12px', width: '20%'}}>
			  <div className="progress-bar bg-success" ref={el => this.audioPreviewElement = el} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		)
		*/
	}
  
}

export { AudioInputMeter, AudioElement };