// const IMAGE_ENDPOINT = process.env.REACT_APP_IMAGE_ENDPOINT;
const IMAGE_ENDPOINT = process.env.PUBLIC_URL;

export default {
	
	imageUrl: (rel) => {
		return `${IMAGE_ENDPOINT}${rel}`
	}
	
}
