import React from 'react';
import { DealersChoiceSettings, SettingsContext } from './Settings';
import { Ellipsis } from '@circleleague/ui'
import Sounds from './media/Sounds';
// import CombinedTable from './table/CombinedTable';
import Table from './table/Table';

import { MeetingSessionConfiguration, ConsoleLogger, DefaultMeetingSession, DefaultDeviceController, LogLevel } from 'amazon-chime-sdk-js';

function Session(props) {
	let { session, connected, game: hand } = props;

	let [settings, setSettings] = React.useState(new DealersChoiceSettings());
	let [settingsTs, setSettingsTs] = React.useState();
	let [audioVideo, setAudioVideo] = React.useState();

	settings.audioVideo.onSettingsChanged = (setting, value) => setSettingsTs(Date.now());

	let audioElement = React.useRef();
	function selectDevice(av, setting, listF, chooseF) {
		let settingsDevice = settings.audioVideo[setting];
	
		return av[listF]()
			.then(devices => {
				let avail = devices.find(d => d.deviceId === settingsDevice);

				if (avail) return settingsDevice;
				if (devices && devices.length > 0) return devices[0].deviceId;
			})
			.then(deviceId => {
				if (!deviceId) return;

				settings.audioVideo[setting] = deviceId;

				return av[chooseF](deviceId);
			})
	}

	let meetingId = session.chime ? session.chime.MeetingId : '';
	let attendeeId;
	if (session.me && session.me.chime) attendeeId = session.me.chime.AttendeeId;

	React.useEffect(z => {
		if (!session.chime || !session.me || !session.me.chime) return;

		console.debug('building meeting session (should only happen once)');

		const logger = new ConsoleLogger('logger', LogLevel.ERROR);
		// const logger = new ConsoleLogger('logger', LogLevel.INFO);

		let meetingSession = new DefaultMeetingSession(
			new MeetingSessionConfiguration(session.chime, session.me.chime),
			logger,
			new DefaultDeviceController(logger));
		
		let { audioVideo: av } = meetingSession;

		if (audioElement.current) av.bindAudioElement(audioElement.current);

		let _videoIn = selectDevice(av, 'videoInputDevice', 'listVideoInputDevices', 'chooseVideoInputDevice');
		let _audoIn = selectDevice(av, 'audioInputDevice', 'listAudioInputDevices', 'chooseAudioInputDevice');
		let _audioOut = selectDevice(av, 'audioOutputDevice', 'listAudioOutputDevices', 'chooseAudioOutputDevice');
		
		Promise.all([_videoIn, _audoIn, _audioOut])
			.then(z => av.start())
			.then(z => av.startLocalVideoTile())
			.then(z => setAudioVideo(av))
	}, [meetingId, attendeeId])

	React.useEffect(z => {
		if (!audioVideo) return;

		console.debug('reloading a/v from settings');

		let _videoIn = selectDevice(audioVideo, 'videoInputDevice', 'listVideoInputDevices', 'chooseVideoInputDevice');
		let _audoIn = selectDevice(audioVideo, 'audioInputDevice', 'listAudioInputDevices', 'chooseAudioInputDevice');
		let _audioOut = selectDevice(audioVideo, 'audioOutputDevice', 'listAudioOutputDevices', 'chooseAudioOutputDevice');

		Promise.all([_videoIn, _audoIn, _audioOut])
			.then(z => {
				if (settings.audioVideo.muted) audioVideo.realtimeMuteLocalAudio()
				else audioVideo.realtimeUnmuteLocalAudio()

				if (settings.audioVideo.videoDisabled)
					return Promise.resolve(audioVideo.stopLocalVideoTile())
						.then(z => audioVideo.chooseVideoInputDevice(null))
					;
				else return audioVideo.startLocalVideoTile();
			})
			.then(z => {
				setSettings(prev => {
					delete prev.audioVideo.onSettingsChanged;
		
					return new DealersChoiceSettings();
				});
			})
	}, [settingsTs, audioVideo])

	/**
	 * NOTE: at this point, session is defined (as guaranteed by App.js), but the state of the
	 * Session may or may not be loaded yet (Session has been joined but state message not yet
	 * received); this can happen on both initial load and reconnects; decendent components all
	 * depends on session state in one way or another, so it doesn't make sense to render those
	 * components; however, we have to render something
	 */
		
	if (!session.table) return <div className="App">
		<div className="game-layer">
			<Ellipsis />
		</div>
	</div>;

	if (session.complete) return (
		<div className="App d-flex flex-column justify-content-center align-items-center" style={{ color: 'white'}}>
		  <div>Your table has been shut down!  Thanks for playing!</div>
		</div>
	)

	return (
		<>
		  <SettingsContext.Provider value={settings}>
		    <Table session={session} hand={hand} audioVideo={audioVideo} wssStatus={connected ? 'open' : 'closed'} />
		  </SettingsContext.Provider>
		  <Sounds session={session} hand={hand} />
		  <audio ref={audioElement}></audio>
		</>
	)
}

export default Session;
