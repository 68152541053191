import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import { chips } from '../table/ChipStack';

import '../ActionButton.css';
import './BetControls.scss';

import {
	faArrowUp as raise,
	faPlus as up,
	faMinus as down
} from '@fortawesome/free-solid-svg-icons'
import PlayerActionMeter from '../player/PlayerActionMeter';

function BetButton(props) {
	const { amount, onButtonClick, label, isDefault, player } = props;
	const [betSubmitted, setBetSubmitted] = React.useState(false);

	const bet = (amount) => {
		onButtonClick(amount);
		setBetSubmitted(true);
	};

	const button = <Button variant="success" disabled={betSubmitted} onClick={z => bet(amount)} className="action-button d-flex flex-column align-items-center justify-content-center">
		<FontAwesomeIcon icon={raise} inverse />
		<div className="action-button-label">${amount}</div>
		{ isDefault && player && player.actionOn && <PlayerActionMeter player={player.id} expirationTime={player.timeoutTs} /> }
	</Button>;

	if (!label) return button;

	return <OverlayTrigger placement="top" overlay={<Tooltip>{`${label} $${amount}`}</Tooltip>}>{button}</OverlayTrigger>
}

function BetControls(props) {
	const { min, max, step, onButtonClick, label, isDefault, player } = props;
	const [amount, setAmount] = React.useState(min);

	if (max < min) return <></>;

	const numPossibleValues = ((max - min) / step) + 1;
	const possibleValues = numPossibleValues <= 3 ? Array.from(Array(numPossibleValues).keys()).map(i => min + (i * step)) : undefined;

	function incrementAmount(increment) {
		const a = amount + increment;
		setAmount(a < min ? min : a > max ? max : a);
	}

	return (
		<>
			{ possibleValues && <div className="d-flex">{ possibleValues.map(value => <div style={{ marginRight: '1vmin' }}>
					<BetButton amount={value} onButtonClick={onButtonClick} label={'RAISE'} sDefault={isDefault} player={player}/>
				</div> )}
			</div>}
			{ !possibleValues && <div className="bet-container">
				<div className="chip-increments">
					{chips.filter(chip => chip.value >= step && chip.value <= (max - min)).map(chip => <div key={chip.value} className="chip-increment">
						<div className="increment-button" onClick={z => incrementAmount(chip.value)}><FontAwesomeIcon icon={up}/></div>
						<div className="chip-badge" style={{backgroundColor: chip.rgb}}>${chip.value}</div>
						<div className="increment-button" onClick={z => incrementAmount(chip.value * -1)}><FontAwesomeIcon icon={down}/></div>
					</div> )}
					<span className="amount">{label} ${amount}</span>
				</div>
				<BetButton amount={amount} onButtonClick={onButtonClick} isDefault={isDefault} player={player}/>
			</div> }
		</>
	);
}

export default BetControls;