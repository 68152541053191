import React from 'react'
import ActionButton from '../ActionButton'

import {
	faPlay as dealIcon,
	faBan as endIcon,
	faDoorOpen as exitIcon,
} from '@fortawesome/free-solid-svg-icons'

const ACTIONS = {
	deal: {
		icon: dealIcon,
		variant: 'success',
		tooltip: 'Start the game',
		order: 0
	},
	end: {
		icon: endIcon,
		variant: 'primary',
		tooltip: 'End this game for everyone',
		order: 4
	},
	exit: {
		icon: exitIcon,
		variant: 'danger',
		tooltip: 'Leave this game',
		order: 5
	}
}

export default function SessionControls(props) {
	let { session } = props;
	let actions = session ? session.actions || {} : {};

	function handleClick(action) {
		session.action(action, {});
	}

	let c = Object.keys(actions).filter(a => ACTIONS[a]);
	c.sort((a1, a2) => ACTIONS[a1].order - ACTIONS[a2].order);
	c = c.map(k => {
		let d = ACTIONS[k] || {};

		return <ActionButton key={d.order}
			icon={d.icon}
			label={d.label}
			variant={d.variant}
			tooltip={d.tooltip}
			rotation={d.rotation}
			onClick={z => handleClick(k)}
			/>
	})

	return (
		<div className="d-flex align-items-end position-relative">
		  { c.map(a => a) }
			{props.children}
		</div>
	)
}