import React from 'react';

import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExclamationTriangle as wssProblemIcon,
	faArrowCircleUp as highIcon,
	faArrowCircleDown as lowIcon,
	faCheck as checkIcon,
	faTimes as foldIcon	
} from '@fortawesome/free-solid-svg-icons'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import MeAudioVideo from '../me/MeAudioVideo';
import MyCards from '../me/MyCards';
import SessionControls from '../me/SessionControls';
import GameControls from '../me/GameControls';
import OutOfPlayStack from '../me/OutOfPlayStack';

import DiscardBadge from '../table/DiscardBadge'
import NoBidBadge from '../table/NoBidBadge'
import ChipStack from '../table/ChipStack'

import './Me.scss'

function MyBadges(props) {
	const { seat, hand, showBest, handleBestHandHover } = props;

	const hasDiscard = hand.me.discarded || hand.me.discarded === 0;
	const hasZeroBids = hand.me.zeroBids || hand.me.zeroBids === 0;
	const declaration = hand.me.declaration;
	const hasHighDeclaration = declaration && declaration.includes('high');
	const hasLowDeclaration = declaration && declaration.includes('low');

	return (
		<>
			{ !hand.me.mucked && <div className="my-badges">
				{ hand.me.dealer && <div className="dealer-button badge">D</div> }
				{ hasDiscard && <DiscardBadge discarded={hand.me.discarded} /> }
				{ hasZeroBids && <NoBidBadge noBids={hand.me.zeroBids} /> }
				{ hasHighDeclaration &&
					<FontAwesomeIcon className={`high-low-toggle ${showBest === 'high' ? 'highlight' : ''} ${hand.me.award > 0 ? 'winner' : ''}`}
					    icon={highIcon}
						size="2x"
						inverse
						onMouseEnter={z => handleBestHandHover('high', hand.me.id)}
					/>
				}
				{ hasLowDeclaration &&
					<FontAwesomeIcon className={`high-low-toggle ${showBest === 'low' ? 'highlight' : ''} ${hand.me.award > 0 ? 'winner' : ''}`}
					    icon={lowIcon}
						size="2x"
						onMouseEnter={z => handleBestHandHover('low', hand.me.id)}
					/> 
				}
			  </div> 
			}
		</>

	);
}

export default function Me(props) {
	let [selectedCards, setSelectedCards] = React.useState([]);

	let { session, hand, wssStatus, showBest, boardSelection } = props;
	let activeHand = hand && hand.me;
	let lastAction = activeHand ? hand.me.lastAction : {};

	let seat = session.table.seats.find(s => s.player && s.player.id === session.me.id) || {};
	let stack = activeHand ? hand.me.stack : session.me.stack;

	let autoPlayAction = activeHand ? hand.actions.autoPlay : undefined;
	let autoPlay = activeHand ? (hand.me.autoPlay || false) : false;

	React.useEffect(z => {
		setSelectedCards([]);
	}, [activeHand && hand.sequence ? hand.sequence.id : ''])

	function selectionChanged(cards) {
		if (hand.actions.exchange) setSelectedCards(cards);
	}

	const autoPlayOnly = autoPlayAction && Object.keys(hand.actions).length === 1;

	return (
		<>
			{ activeHand && !hand.me.mucked && hand.me.cards && <div className="my-cards">
				<MyBadges seat={seat} hand={hand} showBest={showBest} handleBestHandHover={props.handleBestHandHover}/>
				<MyCards hand={hand} selectedCards={selectedCards} showBest={showBest} onSelectionChanged={cards => selectionChanged(cards)} />
				<ChipStack containerClass="my-stack" amount={stack}>
					{ session.me.outOfPlayStack > 0 && <OutOfPlayStack stack={session.me.outOfPlayStack} /> }
				</ChipStack>
			</div> }
			{ activeHand && hand.me.live && <ChipStack containerClass="my-bet" amount={hand.me.totalBet} award={hand.me.award} check={lastAction && lastAction.commonName === 'check'} declaration={hand.me.declaration}/> }
			<div className="me">
				<MeAudioVideo audioVideo={props.audioVideo} session={session} activeHand={activeHand} >
					{ session.me.out && <div className="out-badge badge">OUT</div> }
					{ wssStatus !== 'open' && <div className="wss-alert badge">
						<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>internet connection issue</Tooltip>} >
							<FontAwesomeIcon className="py-0" icon={wssProblemIcon} size="sm" inverse />
						</OverlayTrigger>
					</div> }
				</MeAudioVideo>
					{ activeHand && !hand.me.mucked && hand.me.cards &&
						<div className="my-tray hand-controls">
							<div className="actions" style={{ left: !autoPlayOnly ? '12vmin' : undefined, right: autoPlayOnly ? '6vmin' : undefined }}>
							{/* <div className="actions" style={{ left: `${34 - ((Object.keys(hand.actions).length - 1) * 6.5)}vmin`}}> */}
								{ activeHand && hand.actions && <GameControls hand={hand} selectedCards={selectedCards} boardSelection={boardSelection} /> }
								{ autoPlayOnly && <OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>Auto check or fold {autoPlay ? 'enabled' : 'disabled'}</Tooltip>} >
										<Button className="action-button d-flex flex-column align-items-center justify-content-center" variant="outline-secondary" onClick={z => hand.action('autoPlay', {})} active={autoPlay}>
											<FontAwesomeIcon icon={checkIcon} />
											<FontAwesomeIcon icon={foldIcon} />
										</Button>
									</OverlayTrigger> }
							</div>
						</div>
					}
					{ !activeHand && <div className="my-tray session-controls">
						<SessionControls session={session}>
							<ChipStack containerClass="my-stack" amount={stack}>
								{ session.me.outOfPlayStack > 0 && <OutOfPlayStack stack={session.me.outOfPlayStack} /> }
							</ChipStack>
						</SessionControls>
					</div> }
			</div>
		</>
	)
}
