import React from 'react'

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCog as settingsIcon,
	faMicrophoneSlash as unmuteIcon,
	faMicrophone as muteIcon,
	faVideoSlash as enableVideoIcon,
	faVideo as disableVideoIcon,
	faPlus as addChipsIcon,
	faSignOutAlt as sitOutIcon,
	faSignInAlt as sitInIcon,
	faUser as userIcon
} from '@fortawesome/free-solid-svg-icons'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import { VideoPreview } from '@circleleague/ui';

import { SettingsContext } from '../Settings'
import Devices from '../meeting/Devices';

import './MeAudioVideo.scss';
import AddChipsDialog from './AddChipsDialog';

function Overlay(props) {
	let { session, handleSettingClick, handleAddChipsClick, handleActionClick } = props;

	let replenishAction = session.actions ? session.actions.replenish : null;
	let autoSitOutAction = session.actions ? session.actions.autoSitOut : null;
	let returnAction = session.actions ? session.actions.return : null;

	return (
		  <SettingsContext.Consumer>
			{(settings) => {
				let { muted, videoDisabled } = settings.audioVideo;
				
				return (
					<>
						<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>{muted ? 'unmute' : 'mute'}</Tooltip>} >
							<Button className="mute-button" size="sm" variant="outline-secondary" onClick={z => settings.audioVideo.muted = !muted}>
								<FontAwesomeIcon icon={muted ? unmuteIcon : muteIcon} size="sm" inverse />
							</Button>
						</OverlayTrigger>
						<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>{videoDisabled ? 'enable camera' : 'disable camera'}</Tooltip>} >
					  <Button className="video-button" size="sm" variant="outline-secondary" onClick={z => settings.audioVideo.videoDisabled = !videoDisabled}>
							<FontAwesomeIcon icon={videoDisabled ? enableVideoIcon : disableVideoIcon} size="sm" inverse />
					  </Button>
						</OverlayTrigger>
						<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>settings</Tooltip>} >
							<Button className="settings-button" size="sm" variant="outline-secondary" onClick={handleSettingClick}>
								<FontAwesomeIcon icon={settingsIcon} size="sm" inverse/>
							</Button>
						</OverlayTrigger>
						{ replenishAction && <OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>buy in for more chips</Tooltip>} >
							<Button className="add-chips-button" size="sm" variant="outline-secondary" onClick={handleAddChipsClick}>
								<FontAwesomeIcon icon={addChipsIcon} size="sm" inverse/>
							</Button>
						</OverlayTrigger> }
						{ autoSitOutAction && <OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>sit out next hand</Tooltip>} >
							<Button className="sit-in-out-button" size="sm" variant="outline-secondary" onClick={z => handleActionClick('autoSitOut')} active={session.me.autoSitOut}>
								<FontAwesomeIcon rotation={180} icon={sitOutIcon} size="sm" inverse/>
							</Button>
						</OverlayTrigger> }
						{ returnAction && <OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>return to the game</Tooltip>} >
							<Button className="sit-in-out-button" size="sm" variant="outline-secondary" onClick={z => handleActionClick('return')}>
								<FontAwesomeIcon icon={sitInIcon} size="sm" inverse/>
							</Button>
						</OverlayTrigger> }
					</>
				)
			}}
		  </SettingsContext.Consumer>
	)
}

export default function MeAudioVideo(props) {
	let { audioVideo, session, activeHand } = props;

	let [showDevicesDialog, setShowDevicesDialog] = React.useState(false);
	let [showReplenishDialog, setShowReplenishDialog] = React.useState(false);

	function renderOverlay() {
		return <Overlay session={session} activeHand={activeHand} handleSettingClick={z => setShowDevicesDialog(true)} handleAddChipsClick={z => setShowReplenishDialog(true)}  handleActionClick={handleActionClick}/>
	}

	function addChips(amount) {
		session.action('replenish', { amount });

		setShowReplenishDialog(false);
	}

	function handleActionClick(action) {
		session.action(action, {});
	}

	return (
		<>
		{ audioVideo &&
			<SettingsContext.Consumer>
				{ settings => {
					return <>
						<Devices show={showDevicesDialog} settings={settings} audioVideo={audioVideo} onHide={z => setShowDevicesDialog(false)} />
						{ session.actions && session.actions.replenish && <AddChipsDialog show={showReplenishDialog} addChips={addChips} outOfPlay={activeHand} onHide={z => setShowReplenishDialog(false)} /> }
					</>
				}}
			</SettingsContext.Consumer>
		}

		<SettingsContext.Consumer>
		    {(settings) => {
				let { videoDisabled } = settings.audioVideo;

				if (!audioVideo) return <></>

				let show = !videoDisabled && !audioVideo.videoDisabled;
				if (show) {
					/**
					 * NOTE: this logic is needed because the videoDisabled setting can change and
					 * is applied before the video source has been selected; this prevents the
					 * preview from starting
					 */
					let localTile = audioVideo.getLocalVideoTile();
					if (localTile && localTile.tileState) {
						show = localTile.tileState.localTileStarted;
					}
					else show = false;
				}

				return (
					<div className="my-video-preview">
						<VideoPreview audioVideo={audioVideo} show={show} overlay={renderOverlay} />
						<FontAwesomeIcon icon={userIcon} className="user-icon" inverse/>
						<div className="circle"></div>
						{ props.children }
					</div>
				)}}
	    </SettingsContext.Consumer>
		</>
	)
}