import React from 'react';

import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExchangeAlt as exchange,
	// faMicrophoneSlash as unmuteIcon,
	// faMicrophone as muteIcon,
	faBan as noBid,
	faArrowCircleUp as highIcon,
	faArrowCircleDown as lowIcon,
	faUser as userIcon
} from '@fortawesome/free-solid-svg-icons'

import { CardCollection, flagCardsInBestHand } from '../deck/CardCollection'
import ChipStack from '../table/ChipStack'
import PlayerActionMeter from './PlayerActionMeter'

import { VideoTile } from '@circleleague/ui';

import '../player/PlayerTile.css'

export default function PlayerTile(props) {
	let { seat, sequence, audioVideo, showBest } = props;
	let player = props.player || {};
	let declaration = player.declaration || [];
	let { cards } = player;
	
	if (showBest) {
		let bestHand = player.best[showBest] || player.best;
		cards = flagCardsInBestHand(cards, bestHand, player.award > 0);
	}

	if (sequence && sequence.name === 'push' && player.actionOn) cards[cards.length - 1].pushKeep = true;

	let layout = cards ? props.layout : [];
	let showing = cards && cards.every(card => card.id);

	let shadowClass = player.award ? 'award' : player.stack === 0 ? 'all-in' : null;

	/**
	 * NOTE: prep the parameters used in the overlay function; be sure to spread them on the
	 * video tile, otherwise rendering wont happen on changes (VideoTile is a pure Component);
	 * there may be a better way to implement the overlay (nested component?)
	 */
	let overlayProps = {
		name: seat.player.metadata.name,
		stack: player && player.id ? player.stack : seat.player.stack,
		totalBuyIn: seat.player.totalBuyIn
	}

	let [tileId, setTileId] = React.useState();

	React.useEffect(z => {
		if (!audioVideo) return;

		let observer = {
			videoTileDidUpdate: tileState => {
				// only care about non local (other attendees) tiles
				if (!tileState.boundAttendeeId || tileState.localTile || tileState.isContent) return;

				if (seat.player.chime.AttendeeId === tileState.boundAttendeeId) setTileId(tileState.tileId);
			},
	
			videoTileWasRemoved: removedTileId => {
				console.debug('videoTileWasRemoved', removedTileId);

				if (removedTileId === tileId) setTileId();
				
				// TODO: need to unbind video tile
			}
		}
		
		audioVideo.addObserver(observer);

		/**
		 * NOTE: this gets called when either me or other player refreshes browser, but not when
		 * turning on/off devices; not sure anything will be needed here
		audioVideo.realtimeSubscribeToAttendeeIdPresence((id, isPresent) => {
			console.log(`attendeed presence (id=${id}) (present=${isPresent})`);
		});
		*/

		return z => audioVideo.removeObserver(observer);
	}, [audioVideo]);

	// function mutePlayer() {
	// 	console.debug('implement mute player');
	// }

	function VideoOverlay() {
		// let isMuted = false;
	
		return (
			<div className="player-info">
				<h5 className="player-name">
				<Badge variant="secondary">{overlayProps.name}</Badge>
				{/* <Button className="mx-2 py-0" size="sm" variant='outline-secondary' onClick={mutePlayer}>
				  <FontAwesomeIcon className="py-0" icon={isMuted ? unmuteIcon : muteIcon} size="sm" inverse />
				</Button> */}
				</h5>
				<ChipStack containerClass="player-stack" amount={overlayProps.stack} tooltip={<div style={{ whiteSpace: 'pre-line'}}>{`Current Stack: ${overlayProps.stack}\nTotal Buy In: ${overlayProps.totalBuyIn}`}</div>}/>
			</div>
		)
	}

	return (
		<div className={`player-tile layout-${layout && layout[0] ? layout[0].length : 0}`} onMouseEnter={z => showing ? props.handleBestHandHover(showBest, player.id) : null}>
		  <div className="video-tile">
				<VideoTile audioVideo={audioVideo} tileId={tileId} overlay={VideoOverlay} {...overlayProps} />
				{ shadowClass && <div className={`player-tile-shadow ${shadowClass}`}></div> }
				<FontAwesomeIcon icon={userIcon} className="user-icon" inverse/>
				<div className="circle"></div>
				{ player.actionOn && <PlayerActionMeter player={player.id} expirationTime={player.timeoutTs} /> }
		  </div>

		  <div className="hand">
				{ player.cards && player.live && !player.mucked && <CardCollection cards={cards} layout={layout} /> }
		  </div>

		  { player.live && <ChipStack containerClass="player-bet" amount={player.totalBet} award={player.award} check={player.lastAction && player.lastAction.commonName === 'check'} declaration={declaration} /> }
		  { seat.dealer && <Badge className="dealer-button" variant="warning">D</Badge>}
		  { seat.player.out && <div className="out-button">OUT</div>}

		  { (player.discarded || player.discarded === 0) && <Badge className="discard-badge" variant="primary">
				<FontAwesomeIcon icon={exchange} size="sm" />
				{ player.discarded }
			</Badge> }

		  { (player.zeroBids || player.zeroBids === 0) && <Badge className="discard-badge" variant="danger">
				<FontAwesomeIcon icon={noBid} size="sm" />
				{ player.zeroBids }
			</Badge> }

		  { declaration && <>
			{ declaration.includes('high') &&
				<FontAwesomeIcon className={`high-declaration high-low-toggle ${showBest === 'high' ? 'highlight' : ''} ${player.award > 0 ? 'winner' : ''}`}
				    icon={highIcon}
					size="2x"
					inverse
					onMouseEnter={z => showing ? props.handleBestHandHover('high', player.id) : null}
			/> }

			{ declaration.includes('low') &&
			    <FontAwesomeIcon className={`low-declaration high-low-toggle ${showBest === 'low' ? 'highlight' : ''} ${player.award > 0 ? 'winner' : ''}`}
			        icon={lowIcon}
					size="2x"
					onMouseEnter={z => showing ? props.handleBestHandHover('low', player.id) : null}
				/> }
			</> }
	</div>
	)
}