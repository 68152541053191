import React from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import ActionButton from '../ActionButton'
import Declare from './Declare'

import {
	faCheck as checkIcon,
	faTimes as foldIcon,
	faHandPointLeft as pushIcon,
	faHandPointDown as keepIcon,
	faExchangeAlt as exchangeIcon,
	faEye as showIcon,
	faTrashAlt as muckIcon,
} from '@fortawesome/free-solid-svg-icons'
import BetControls from './BetControls';

function RaiseControl(props) {
	let { hand, action, yieldId, isDefault, player } = props;

	let handler = amount => hand.action('raise', { amount });

	return <BetControls key={yieldId} min={action.min} max={action.max} step={1} onButtonClick={handler} label={'RAISE'} isDefault={isDefault} player={player}/>
}
RaiseControl.order = 1

function ExchangeControl(props) {
	let { hand, selectedCards, action, player, isDefault } = props;

	function exchangeCards() {
		hand.action('exchange', { cards: selectedCards.map(c => c.id) })
	}
	
	return (
		<OverlayTrigger placement="top" overlay={<Tooltip>Click the cards you wish to discard (max {action.max}) and then click the Discard button.</Tooltip>} >
		  <div className="discard-button">
				<ActionButton icon={exchangeIcon} variant="primary" disabled={!action.submit} onClick={exchangeCards} player={player} isDefault={isDefault}/>
		  </div>
		</OverlayTrigger>
	)
}
ExchangeControl.order = 5;

function BidControl(props) {
	let { hand, action, isDefault, player } = props;

	let handler = amount => hand.action('bid', { amount });

	return <BetControls min={action.min} max={action.max} step={1} onButtonClick={handler} label={'BID'} isDefault={isDefault} player={player}/>
}
BidControl.order = 1

function ChooseControl(props) {
	let { hand, boardSelection, player, isDefault } = props;

	function chooseCard() {
		hand.action('choose', { card: boardSelection[0].id })
	}

	return (
		<OverlayTrigger placement="top" overlay={<Tooltip>Click the community card you wish to choose and then click the Choose button.</Tooltip>} >
		  <div className="discard-button">
			<ActionButton icon={keepIcon} variant="primary" disabled={boardSelection.length < 1} onClick={chooseCard} player={player} isDefault={isDefault}/>
		  </div>
		</OverlayTrigger>
	)
}
ChooseControl.order = 1;

function DeclareControl(props) {
	let { hand, player, isDefault } = props;

	return <Declare hand={hand} player={player} isDefault={isDefault}/>
}
DeclareControl.order = 6;

const ACTIONS = {
	match: {
		icon: checkIcon,
		label: a => a.current ? '$' + a.current : '',
		variant: 'primary',
		tooltip: a => a.current ? `call $${a.current}` : `check`,
		order: 0
	},
	raise: RaiseControl,
	fold: {
		icon: foldIcon,
		label: a => '',
		variant: 'danger',
		tooltip: a => 'fold',
		className: 'fold-button',
		order: 2
	},
	keep: {
		icon: keepIcon,
		label: a => '',
		variant: 'success',
		tooltip: a => 'keep card',
		order: 3
	},
	push: {
		icon: pushIcon,
		label: a => '',
		variant: 'primary',
		tooltip: a => 'push card',
		order: 4
	},
	bid: BidControl,
	choose: ChooseControl,
	exchange: ExchangeControl,
	declare: DeclareControl,
	show: {
		icon: showIcon,
		label: a => '',
		variant: 'info',
		tooltip: a => 'show my hand',
		order: 7
	},
	muck: {
		icon: muckIcon,
		label: a => '',
		variant: 'primary',
		tooltip: a => 'muck my hand',
		order: 8
	},
}

export default function GameControls(props) {
	let { hand, selectedCards, boardSelection } = props;
	let player = hand ? hand.me || {} : {};
	let actions = hand ? hand.actions || {} : {};
	let sequenceId = hand && hand.sequence ? hand.sequence.id : undefined;
	let yieldId = hand && hand.sequence ? hand.sequence.yieldId : undefined;

	function handleClick(action) {
		hand.action(action, {});
	}

	let c = Object.keys(actions).filter(a => ACTIONS[a]);
	c.sort((a1, a2) => ACTIONS[a1].order - ACTIONS[a2].order);
	c = c.map(k => {
		let d = ACTIONS[k] || {};

		if (d.icon) return (
			<div className={d.className} key={`${yieldId}-${k}`} style={{ marginRight: '2vmin' }}>
			  <ActionButton
			    icon={d.icon}
			    label={d.label(actions[k])}
			    variant={d.variant}
			    tooltip={d.tooltip(actions[k])}
			    onClick={z => handleClick(k)}
					player={player}
					isDefault={hand && hand.sequence && hand.sequence.defaultAction.name === k}
			  />
			</div>
		)

		let Control = d;  // component has to be camel cased
		return <Control
		  key={sequenceId}
		  hand={hand}
		  player={player}
		  action={actions[k]}
		  selectedCards={selectedCards}
		  boardSelection={boardSelection}
		  yieldId={`${yieldId}-${k}`}
			isDefault={hand && hand.sequence && hand.sequence.defaultAction.name === k}
		/>
	})

	return <>{ c.map(a => a) }</>
}