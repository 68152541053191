import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';

import { ServerTime, createServerTime } from './ServerTime'
import Monitor from './monitoring/monitor'
import CompatibilityChecklist from './room/CompatibilityChecklist'
import Landing from './room/Landing';
import Support from './Support';
import HarnessLayer from './harness/HarnessLayer';

import Session from './Session'

import { Ellipsis } from '@circleleague/ui'

import ReconnectingWebSocket from 'reconnecting-websocket';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// import Monitor from './monitoring/monitor'

/**
 * for Okta authenticated services only
 */
const oktaLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('okta-token-storage');

	if (token) {
		let parsed = JSON.parse(token);
		
		return {
			headers: {
				...headers,
				authorization: `Bearer ${parsed.accessToken.accessToken}`
			}
		}
	}

	return { headers };
});

const playerLink = setContext((_, { headers }) => {
	const token = sessionStorage.getItem('player.accessToken');

	if (token) {
		return {
			headers: {
				...headers,
				authorization: `Bearer ${token}`
			}
		}
	}

	return { headers };
});

function LoadingPage() {
	return (
		<div className="LoadingPage d-flex justify-content-center align-items-center">
		  <Ellipsis />
		</div>
	)
}

/**
 * Handles websocket events and renders appropriate view based on a variety of states
 * 
 * CONNECTING
 * 	- initial component state until ecosystem "connect" message is received (first time only)
 * 	- renders ellipsis
 * 
 * CONNECTED/NO SESSION
 * 	- ecosystem "connect" message but no active session returned
 * 	- renders "new session" (landing) view
 * 
 * CONNECTED/SESSION/SYSTEM NOT CHECKED
 *  - ecosystem "connect" message with active session returned
 * 	- compatibility checklist has not run
 * 	- render compatibility checklist
 * 
 * CONNECTED/SESSION/SYSTEM CHECKED (or local ecosystem)
 *  - ecosystem "connect" message with active session returned
 * 	- render session
 * 
 * @param {*} props 
 * @returns 
 */
function Room(props) {
	let [connecting, setConnecting] = React.useState(true);
	let [connected, setConnected] = React.useState(false);
	let [systemChecked, setSystemChecked] = React.useState(false);
	let [ecosystem, setEcosystem] = React.useState();
	let [session, setSession] = React.useState();
	let [game, setGame] = React.useState();
	let [serverTime, setServerTime] = React.useState(createServerTime(Date.now()));

	let { ws } = props;

	React.useEffect(z => {
		if (!ws) return;

		ws.onclose = z => {
			setConnected(false);

			/**
			 * NOTE: handle close better...this is a disconnect, which may be quick and temporary;
			 * don't want to clear out the ecosystem, but some feedback to the user should be
			 * given, buttons disabled, etc
			 */
			//  this.monitor.event('DISCONNECT', { endpoint: this.runtimeEndpoint });
		}
		ws.onopen = z => {
			/**
			 * NOTE: legacy "authorization" support; ecosystems that allow playerId with connect
			 * message will authorize the connection for the specified player; production will
			 * eventually not allow this (using HttpOnly on the cookie) for better security; ws
			 * endpoint will have to use same origin as the ui in this case
			 */
			let playerCookie = document.cookie.split('; ')
				.find(cookieS => cookieS.startsWith('circleleague-player='))
				;
			let playerId = playerCookie ? playerCookie.split('=')[1] : undefined;
		  
			ws.send(JSON.stringify({ route: 'ecosystem-connect', runtime: 'ecosystem', type: 'connect', playerId }));

			setConnected(true);
		}
		ws.onmessage = event => {
			let message = JSON.parse(event.data);

			if (message.runtime === 'ecosystem' && message.type === 'connect') {
				/**
				 * At this point we could be in various states depending on the returned ecosystem
				 * 		- unknown player (did not submit cookie)
				 * 		- known player (submitted cookie)
				 */

				let facade = message.state;
				facade.guestApi = new ApolloClient({
					uri: message.state.api.guest.endpoint,
					cache: new InMemoryCache()
				})

				setEcosystem(facade);
				setConnecting(false);

				if (message.state.session)
					ws.send(JSON.stringify({ route: 'session-join', runtime: 'session', type: 'join' }))
			}

			if (message.runtime === 'session' && message.type === 'state') {
				let newSession = message.state;
				newSession.action = (name, parameters) => {
					return ws.send(JSON.stringify({
						route: 'session-action',
						runtime: 'session',
						type: 'action',
						action: { name, parameters }
					}));
				}

				setSession(newSession);

				if (!newSession.table.activeHand) setGame();

				if (newSession.clock) setServerTime(createServerTime(newSession.clock.time));
			}

			if (message.runtime === 'hand' && message.type === 'state') {
				let newGame = message.state;
				newGame.action = (name, parameters) => {
					return ws.send(JSON.stringify({
						route: 'hand-action',
						runtime: 'hand',
						type: 'action',
						target: message.state.id,
						action: { name, parameters }
					}));
				}

				setGame(newGame);

				if (newGame.clock) setServerTime(createServerTime(newGame.clock.time));
			}

			// TODO: update current time with heartbeats
		}

		return z => ws.close();
	}, [ws]);

	let activeHand = session && session.table ? session.table.activeHand : undefined;
	React.useEffect(z => {
		if (!ws || !activeHand) return;

		ws.send(JSON.stringify({
			route: 'hand-reload',
			runtime: 'hand',
			target: activeHand,
			type: 'reload'
		}));
	}, [activeHand])

	if (connecting) return <LoadingPage />

	let isLocal = ecosystem && ecosystem.local;

	if (ecosystem && !ecosystem.session) {
		return (
			<div>
		      <div className="landing-layer">
			    <Landing ecosystem={ecosystem} />
              </div>

			  { isLocal && <HarnessLayer ecosystem={ecosystem} session={session} game={game} ws={ws} /> }
			</div>
		)
	}

	if (systemChecked || isLocal) {
		return (
			<ServerTime.Provider value={serverTime}>
			  <div className="App Session">
		        <div className="game-layer">
		          { session && <Session session={session} game={game} connected={connected} /> }
                </div>

			    { isLocal && <HarnessLayer ecosystem={ecosystem} session={session} game={game} ws={ws} /> }
			  </div>
			</ServerTime.Provider>
		)
		// { isLocal && <LocalHarness onSessionChange={session => this.sessionChanged(session)} /> }
	}

	if (session && !isLocal) return <div className="App">
		<div className="game-layer">
			<CompatibilityChecklist handleLaunch={z => setSystemChecked(true)}/>
		</div>
	</div>

	return <LoadingPage />
}

class App extends React.Component {

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error
		}
	}

	monitor = new Monitor()

	state = {
		hasError: false
	}

	componentDidCatch(error, info) {
		/**
		 * NOTE: the info object does have a componentStack key that has useful information, but
		 * only in development; so really there is no way to get useful stack traces from
		 * production into DD; could move error boundary down some to get more useful context (i.e
		 * SESSION/HAND/etc)
		 */
		this.monitor.event('REACT_ERROR', { boundary: 'APP' });
	}

	componentDidMount() {
		let endpoint = process.env.REACT_APP_WSS_ENDPOINT;
		
		console.debug(`connecting to ecosystem at ${endpoint}`);

		this.setState({
			ws: new ReconnectingWebSocket(endpoint, [], { maxEnqueuedMessages: 0, debug: false })
		})
	}

	render() {
		let { hasError, error, ws } = this.state;

		if (hasError) return <Support error={ error } />

		return (
			<div className="App">
			  <Room ws={ws} />
			</div>
		)
	}

}

export default App;