import React from 'react';

import { Badge } from 'react-bootstrap';

import { ServerTime } from '../ServerTime'

import '../player/PlayerActionMeter.css'

const FULL_DASH_ARRAY = 283;

const COLOR_CODES = {
  info: {
    color: "green",
    variant: "success"
  },
  warning: {
    color: "orange",
    variant: "warning",
    threshold: .5
  },
  alert: {
    color: "red",
    variant: "danger",
    threshold: .25
  }
};

function getCircleDasharray(timeLimit, remainingPercent) {
  return `${((remainingPercent - (1 / timeLimit) * (1 - remainingPercent)) * FULL_DASH_ARRAY).toFixed(0)} 283`;
}

function getRemainingPathColor(remainingPercent) {
  const { alert, warning, info } = COLOR_CODES;
  if (remainingPercent <= alert.threshold) return alert.color;
  if (remainingPercent <= warning.threshold) return warning.color;
  return info.color;
}

function getRemainingVariant(remainingPercent) {
  const { alert, warning, info } = COLOR_CODES;
  if (remainingPercent <= alert.threshold) return alert.variant;
  if (remainingPercent <= warning.threshold) return warning.variant;
  return info.variant;
}

function useTimer(expirationTime=Date.now(), updateInterval=1000) {
	let serverTime = React.useContext(ServerTime);
	let [currentTime, setCurrentTime] = React.useState(serverTime.currentTime());
	let [remainingTime, setRemainingTime] = React.useState(0);
	let [remainingPercent, setRemainingPercent] = React.useState(0);
	let [totalTime, setTotalTime] = React.useState(0);

	// update the current time anytime the server time changes
	React.useEffect(z => setCurrentTime(serverTime.currentTime()), [serverTime.lastServerTs]);

	React.useEffect(z => setTotalTime(expirationTime - currentTime), [expirationTime]);

	React.useEffect(z => {
		let remaining = expirationTime - currentTime;
		
		// console.debug('updating timer', remaining);

		if (remaining < 0) {
			setRemainingTime(0);
			setRemainingPercent(0);

			return;
		};
		
		let timeoutVal = remaining < updateInterval ? remaining : updateInterval;
		let i = setTimeout(z => {
			setCurrentTime(currentTime + timeoutVal);

			remaining -= timeoutVal;

			if (timeoutVal < updateInterval) {
				setRemainingTime(0);
				setRemainingPercent(0);
			}
			else {
				setRemainingTime(remaining);
				setRemainingPercent(remaining/totalTime);
			}
		}, timeoutVal);

		// console.debug('###########', new Date(currentTime).toLocaleTimeString(), remainingTime, totalTime, remainingPercent);

		return z => clearInterval(i);
	}, [remainingTime])  // NOTE: prevents multiple timeouts from being scheduled

	return [remainingTime, remainingPercent, currentTime];
}

const PlayerActionMeter = (props) => {
  const { expirationTime, meterType } = props;

  let [timerValue, remainingPercent] = useTimer(expirationTime, 100);
  if (timerValue === 0 && props.onExpired) props.onExpired();

	// console.debug('********', timerValue, remainingPercent);

  return (
    <>
      { (!meterType || meterType === 'cirle') && <div className="base-timer">
        <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g className="base-timer__circle">
            <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
            <path id="base-timer-path-remaining" strokeDasharray={getCircleDasharray(timerValue, remainingPercent)} className={`base-timer__path-remaining ${getRemainingPathColor(remainingPercent)}`}
              d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
            ></path>
          </g>
        </svg>
      </div> }
      { meterType === 'badge' && <Badge className="mx-2 px-2" variant={getRemainingVariant(remainingPercent)}>{Math.trunc(timerValue/1000)}</Badge> }
    </>
  );
};

export default PlayerActionMeter;