import {
	faGoogle as googleIcon
} from '@fortawesome/free-brands-svg-icons';
import {
	faCheck as selectIcon,
	faEnvelope as emailIcon, faHeadset as newSessionIcon,
	faPlay as startIcon,
	faPlusCircle as addIcon, faStepForward as nextIcon,
	faUser as playerIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
	Accordion,
	AccordionContext,
	Button,
	Card,
	CardDeck,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
	useAccordionToggle
} from 'react-bootstrap';

import { useMutation, gql } from '@apollo/client';

import env from '../env';

const IMAGES = {
	game: env.imageUrl("/deck/honor_clubs.png"),
}

const styles = {
	page: {
		padding: 20,
		// backgroundColor: 'red'
	},

	container: {
		padding: 10,
		width: '60%',
		// backgroundColor: 'green'
	},

	form: {
		marginTop: '3vh',
		marginBottom: '2vh',
		// backgroundColor: 'gray'
	},

	gameCard: {
		// backgroundColor: 'blue'
		color: 'black',
	}
}

const GAMES = [{
	id: 100,
	title: "Cash Game",
	description: "Continous poker play for up to 8 players.  Choose from Holdem, Omaha, Stud, and more.  Single poker variation or Dealer's Choice"
}, {
	id: 101,
	title: "Tournament",
	description: "Tournament poker play for up to 8 players.  Choose from Holdem, Omaha, Stud, and more"
}]

const EMAIL_REGEX = /^\S+@\S+$/;

const CREATE_SESSION = gql`
	mutation CreateSession($gameId: ID!, $host: String!, $players: [String]!) {
		createSessionLegacy(gameId: $gameId, host: $host, players: $players) {
			id
			host {
				id
			}
			joinLink
		}
	}
`;

function StepToggler({ step, current }) {
	const currentStep = React.useContext(AccordionContext);
	const [open, setOpen] = React.useState(false);
	const toggle = useAccordionToggle(step, z => {});

	// console.debug('$$$$$$$$', step, open, current, currentStep);

	React.useEffect(z => {
		setOpen(step === current);

		if (!open && step === current) {
			// console.debug('toggling', step);

			toggle();
		}
	}, [current])

	return null;
}

function StepCircle(props) {
	return (
		<div className="bg-info d-flex justify-content-center align-items-center rounded-circle" style={{ width: 60, height: 60 }}>
		  <h3>{props.value}.</h3>
	    </div>
	)
}

function StepHeader(props) {
	return (
		<div className="mt-5 mb-3 d-flex justify-content-center align-items-end">
		  <StepCircle value={props.stepNumber} />
		  <h4 className="ml-3">{props.title}</h4>
		</div>
	)
}

function NextButton(props) {
	return (
		<div className="mt-3 d-flex justify-content-end">
		  <Button variant="success" {...props}>
		    <FontAwesomeIcon className="mr-3" icon={nextIcon} size="lg" />
		    Next
		  </Button>
	    </div>
	)	
}

function EmailControl(props) {
	let { showErrors } = props;

	let [value, setValue] = React.useState('');
	let [changed, setChanged] = React.useState(false);
	let [valid, setValid] = React.useState(true);

	React.useEffect(z => {
		let hasValue = value && value.length > 0;
		if (hasValue) setValid(EMAIL_REGEX.test(value));
		else setValid(!props.required);
	}, [value, props.required])

	function update(e) {
		let value = e.target.value.trim();

		setValue(value);
		setChanged(true);
	}

	function commit(e) {
		e.preventDefault();

		if (changed) {
			props.onUpdated(value);
	
			setChanged(false);
		}
	}

	return (
		<InputGroup hasValidation>
		  <InputGroup.Prepend>
		    <InputGroup.Text><FontAwesomeIcon icon={emailIcon} size="sm" /></InputGroup.Text>
		  </InputGroup.Prepend>
		  <Form.Control htmlSize="50" type="email" placeholder={props.placeholder}
		  	onChange={update}
			onBlur={commit}
			required isInvalid={!valid && showErrors}
			/>
		  <Form.Control.Feedback type="invalid">
	        Enter a valid email address
	      </Form.Control.Feedback>
		</InputGroup>
	)
}

function NameControl(props) {
	let { showErrors } = props;

	let [value, setValue] = React.useState('');
	let [changed, setChanged] = React.useState(false);
	let [valid, setValid] = React.useState(true);

	function update(e) {
		let value = e.target.value.trim();

		setValue(value);
		setChanged(true);
		setValid(value.length < 13);
	}

	function commit(e) {
		e.preventDefault();

		if (changed) {
			props.onUpdated(value);
	
			setChanged(false);
		}
	}

	return (
		<InputGroup hasValidation>
		  <InputGroup.Prepend>
		    <InputGroup.Text><FontAwesomeIcon icon={playerIcon} size="sm" /></InputGroup.Text>
		  </InputGroup.Prepend>
		  <Form.Control htmlSize="50" maxLength={12} type="text" placeholder="Optional"
		      onChange={update}
			  onBlur={commit}
			  isInvalid={!valid && showErrors}
			  />
		  <Form.Control.Feedback type="invalid">
	        Max length is 12 characters
	      </Form.Control.Feedback>
		</InputGroup>
	)
}

function GameCard(props) {
	return (
		<Card style={styles.gameCard}>
		  <Card.Img variant="top" src={IMAGES.game} />
		  <Card.Body>
			<Card.Title>{props.game.title}</Card.Title>
			<Card.Text className="text-muted">
			  {props.game.description}
			</Card.Text>
		  </Card.Body>
		  <Card.Footer className="d-flex justify-content-center">
			<Button variant="primary" onClick={z => props.onGameSelect(props.game)}>
  			  <FontAwesomeIcon className="mr-3" icon={selectIcon} size="lg" />
  			  Select
			</Button>
		  </Card.Footer>
		</Card>
	)
}

function ChooseGame({ active, onComplete }) {
	function selectGame(game) {
		onComplete(game);
	}

	return (
		<div>
		  <div className="mt-1 text-muted d-flex justify-content-center">
			<i>* Circle League is not a gambling site</i>
		  </div>
		  <CardDeck className="p-5 d-flex justify-content-center">
			{
				GAMES.map(game => <GameCard key={game.id} game={game} onGameSelect={selectGame} />)
			}
		  </CardDeck>
		</div>
	)
}

function AddPlayersForm({ onComplete }) {
	let [playerId, setPlayerId] = React.useState(1);
	let [players, setPlayers] = React.useState([]);
	let [showErrors, setShowErrors] = React.useState(false);

	React.useEffect(z => {
		if (players.length === 0) {
			let localId = playerId;

			setPlayers([
				{ id: localId++, required: true },
				{ id: localId++ },
				{ id: localId++ },
			])

			setPlayerId(localId);
		}
	})

	function addPlayer() {
		let player = { id: playerId + 1 };

		let newPlayers = Array.from(players);
		newPlayers.push(player);

		setPlayers(newPlayers);
		setPlayerId(player.id);
	}

	function removePlayer(index) {
		let newPlayers = Array.from(players);
		newPlayers.splice(index, 1);

		setPlayers(newPlayers);
	}

	function updatePlayerEmail(player, email) {
		let newPlayers = Array.from(players);
		player.email = email;

		setPlayers(newPlayers);
	}

	function updatePlayerName(player, name) {
		let newPlayers = Array.from(players);
		player.name = name;

		setPlayers(newPlayers);
	}

	function next() {
		let completedPlayers = players.filter(p => EMAIL_REGEX.test(p.email));
		let partiallyCompleted = players.filter(p => (p.name && p.name.length > 0) && !EMAIL_REGEX.test(p.email));

		let valid = completedPlayers.length > 0 && partiallyCompleted.length === 0;

		if (valid) {
			onComplete(completedPlayers);

			// setPlayers([]);
			// setShowErrors(false);
		} else {
			let newPlayers = Array.from(players);
			newPlayers.forEach(p => p.required = false);
			partiallyCompleted.forEach(p => p.required = true);

			if (players.length === 1 || partiallyCompleted.length === 0) players[0].required = true;

			setPlayers(newPlayers);
			setShowErrors(true);
		}
	}

	return (
		<>
			<div className="mt-1 d-flex flex-column align-items-center">
			  <p>
				<b>Additional players</b> can be added once the game has started
			  </p>
			</div>

			<Container fluid style={styles.form}>
			  <Row>
			    <Col xs={1}></Col>
			    <Col xs={5}><h5>Player Email</h5></Col>
    			<Col xs={5}><h5>Name (optional)</h5></Col>
    			<Col xs={1}></Col>
  			  </Row>
			  { players.map((p, index) => {
				  return (
			          <Row key={p.id} className="mt-3">
			            <Col xs={1}></Col>
			            <Col xs={5}>
						  <EmailControl showErrors={showErrors} placeholder="player@example.com"
						  	onUpdated={email => updatePlayerEmail(p, email)}
							required={p.required}
						  />
						</Col>
			            <Col xs={5}>
						  <NameControl showErrors={showErrors} onUpdated={name => updatePlayerName(p, name)} />
						</Col>
    			        <Col xs={1}>
						  { players.length > 1 && <Button variant="outline-secondary" onClick={z => removePlayer(index)}>X</Button> }
						</Col>
  			          </Row>
				  )
			  })}
			  <Row className="mt-3">
			    <Col xs={1}></Col>
			    <Col xs={5}>
				  <Button variant="link" onClick={addPlayer} disabled={players.length > 6}>
			        <FontAwesomeIcon className="mr-3" icon={addIcon} size="lg" />
				    Add another
			      </Button>
				</Col>
			    <Col xs={5}>
				  <Button variant="link">
			        <FontAwesomeIcon className="mr-3" icon={googleIcon} size="lg" />
				    Connect Google Contacts
			      </Button>
				</Col>
    			<Col xs={1}></Col>
  			  </Row>
			</Container>

			<NextButton onClick={next} />
		</>
	)
}

function StartGame({ onStart }) {
	return (
		<div className="mt-1 d-flex flex-column align-items-center">
		  <p>
		    <b>The poker table</b> will open in this browser window
		  </p>
		  <p>
		    <b>Each player</b> will be emailed with a personalized link to join the game.
		  </p>

		  <div className="mt-3 d-flex justify-content-end">
		    <Button variant="success" size="lg" onClick={onStart}>
		      <FontAwesomeIcon className="mr-3" icon={startIcon} size="lg" />
		      Start
		    </Button>
	      </div>
		</div>
	)
}

function StepOne({ game, activeStep, onComplete, onChange }) {
	const currentStep = React.useContext(AccordionContext);

	const stepActive = currentStep === 1;

	function gameSelected(game) {
		onComplete(game);
	}

	return (
		<>
		<StepToggler step={1} current={activeStep} />
		<div className="mt-5 mb-3 d-flex justify-content-center align-items-end">
		  <StepCircle value={1} />
		  { (stepActive || !game) && <h4 className="ml-3">Choose the type of game</h4> }
		  { (!stepActive && game) && 
				<>
				<h4 className="ml-3 mr-1 mb-0">
				  Play a { game.title }
				  <Button variant="link" onClick={onChange}>Change</Button>
				</h4>
				</>
		  }
		</div>
		<Accordion.Collapse eventKey={1}>
		  <ChooseGame onComplete={gameSelected} />
		</Accordion.Collapse>
		</>
	)
}

function StepTwo({ activeStep, onComplete, onChange }) {
	let [host, setHost] = React.useState();
	let [showErrors, setShowErrors] = React.useState(false);
	const currentStep = React.useContext(AccordionContext);

	const stepActive = currentStep === 2;

	function next() {
		let hostValid = EMAIL_REGEX.test(host);

		if (hostValid) onComplete(host);
		else setShowErrors(true);
	}

	return (
		<>
		<StepToggler step={2} current={activeStep} />
		<div className="mt-5 mb-3 d-flex justify-content-center align-items-end">
		  <StepCircle value={2} />
		  { (stepActive || !host) && <h4 className="ml-3">Enter your email address</h4> }
		  { (!stepActive && host) && 
				<>
				<h4 className="ml-3 mr-1 mb-0">
				  Hosted by { host }
				  <Button variant="link" onClick={onChange}>Change</Button>
				</h4>
				</>
		  }
		</div>
		<Accordion.Collapse eventKey={2}>
		  <>
			<Form style={styles.form}>
			  <Form.Row className="justify-content-center align-items-center">
			    <Col xs={8}>
				  <EmailControl placeholder="host@example.com" showErrors={showErrors} required={true}
				  	onUpdated={setHost}
				  	/>
				</Col>
			  </Form.Row>
			</Form>
			<div className="mt-1 d-flex justify-content-center ">
			  or <a href="#" className="pl-2 pr-2 text-decoration-none">Login</a> if you have an account
			</div>
			<NextButton onClick={next} />
		  </>		
		</Accordion.Collapse>
		</>
	)
}

function StepThree({ activeStep, onComplete, onChange }) {
	let [players, setPlayers] = React.useState([]);
	const currentStep = React.useContext(AccordionContext);

	const stepActive = currentStep === 3;

	function playersCommited(players) {
		setPlayers(players);

		onComplete(players);
	}

	return (
		<>
		<StepToggler step={3} current={activeStep} />
		<div className="mt-5 mb-3 d-flex justify-content-center align-items-end">
		  <StepCircle value={3} />
		  { (stepActive || players.length === 0) && <h4 className="ml-3">Invite players to the game</h4> }
		  { (!stepActive && players.length > 0) && 
				<>
				<h4 className="ml-3 mr-1 mb-0">
				  Inviting { players.length } player{players.length > 1 && 's'}
				  <Button variant="link" onClick={onChange}>Change</Button>
				</h4>
				</>
		  }
		</div>
		<Accordion.Collapse eventKey={3}>
		  <>
		    <AddPlayersForm onComplete={playersCommited} />
		  </>		
		</Accordion.Collapse>
		</>
	)
}

function HostSessionWizard({ ecosystem }) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [game, setGame] = React.useState();
	const [host, setHost] = React.useState();
	const [players, setPlayers] = React.useState([]);

	let [createSession, createSessionResponse] = useMutation(CREATE_SESSION, {
		client: ecosystem.guestApi
  	});

	React.useEffect(z => {
		setTimeout(z => setActiveStep(1), 1000);
	}, []);

	React.useEffect(z => {
		let { called, loading, data } = createSessionResponse;

		if (called && !loading) {
			window.location = data.createSessionLegacy.joinLink;
		}
	})

	function completeChooseGame(game) {
		setGame(game);

		console.debug('step1', game);

		setActiveStep(2);
	}

	function completeHostEmail(host) {
		setHost(host);

		console.debug('step2', host);

		setActiveStep(3);
	}

	function completeInvitePlayers(players) {
		setPlayers(players);

		console.debug('step3', players);

		setActiveStep(4);
	}

	let startGame = z => {
		console.debug(game);
		console.debug(host);
		console.debug(players);

		return createSession({
			variables: {
				gameId: game.id,
				host,
				players: players.map(p => p.email)
			}
		});
	}

	return (
		<div className="d-flex justify-content-center align-items-center" style={styles.page}>
		  <div className="d-flex flex-column justify-content-center" style={styles.container}>
			<h1 className="text-center">
			  <FontAwesomeIcon className="mr-3" icon={newSessionIcon} size="2x"/> Host a <b>Poker Game</b>
			</h1>

			<Accordion>
			  <StepOne game={game} activeStep={activeStep} onComplete={completeChooseGame} onChange={z => setActiveStep(1)} />

			  <StepTwo activeStep={activeStep} onComplete={completeHostEmail} onChange={z => setActiveStep(2)} />

			  <StepThree activeStep={activeStep} onComplete={completeInvitePlayers} onChange={z => setActiveStep(3)} />

			  <StepToggler step={4} current={activeStep} />
		      <StepHeader stepNumber={4} title="Start the game!" />
		      <Accordion.Collapse eventKey={4}>
			    <StartGame onStart={startGame} />
		      </Accordion.Collapse>
			</Accordion>
		  </div>
		</div>	
	)
}

export default HostSessionWizard;