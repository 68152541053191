import React from 'react';

import { Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faBan as noBid,
} from '@fortawesome/free-solid-svg-icons'

export default function NoBidBadge(props) {
	let { noBids } = props;

	return (
		<Badge variant="danger" className="no-bid">
		  <FontAwesomeIcon className="mx-1" icon={noBid} size="sm" />
		  { noBids }
	  </Badge>
	)
}
