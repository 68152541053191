import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'bf719212-7019-4e7a-9e03-66308e6447f6',
    clientToken: 'pubd02fd6a5d59e906fe8d101e634f63078',
    site: 'datadoghq.com',
 	service: 'poker',
 	env: process.env.NODE_ENV,
//  version: '1.0.0',
    sampleRate: 100,
    trackInteractions: false
});

datadogRum.addRumGlobalContext('dlr-session', sessionStorage.getItem('dc.session'));
datadogRum.addRumGlobalContext('dlr-player', sessionStorage.getItem('dc.player'));

datadogRum.addUserAction('initializing', {
	wssEndpoint: process.env.REACT_APP_WSS_ENDPOINT,
	imageEndpoint: process.env.REACT_APP_IMAGE_ENDPOINT
});

class Monitor {

	error(name, info) {}

	event(name, context) {
		// no need to log errors (will still show in dev)
		datadogRum.addUserAction(name, context);
	}

}

export default Monitor;