import React from 'react';
import { EmptySpacer, maskImage, downCard } from './SelectableCard'

import './SelectableCard.css';

export default function Placeholder(props) {
	let { type, masked, cardSize } = props;

	return (
		<div className="CardContainer">
			<div className="card-sizer" style={cardSize}>
				<EmptySpacer className={`Card ${type === 'outline' ? 'PlayingCard outline' : ''}`} />
				{ type === 'outline' && masked && <>
						<div>{ maskImage }</div>
					</>
				}
				{ type === 'facedown' && downCard }
			</div>
		</div>
	)
}