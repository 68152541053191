import React from 'react';

import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import PlayerActionMeter from './player/PlayerActionMeter';

import './ActionButton.css'

export default function ActionButton(props) {
	const { variant, onClick, active, disabled, icon, rotation, label, tooltip, player, isDefault } = props;

	const button = <Button className={`action-button d-flex flex-column align-items-center justify-content-center ${isDefault ? 'mx-1' : ''}`} variant={variant} onClick={onClick} active={active} disabled={disabled}>
		{ icon && <FontAwesomeIcon icon={icon} rotation={rotation} inverse /> }
		{ label && <div className="action-button-label">{label}</div> }
		{ isDefault && player && player.actionOn && <PlayerActionMeter player={player.id} expirationTime={player.timeoutTs} /> }
	</Button>;
	if (!tooltip) return button;
	return (
		<>
			<OverlayTrigger placement="top" overlay={<Tooltip style={{ textTransform: 'uppercase' }}>{tooltip}</Tooltip>} >
				{button}
			</OverlayTrigger>
		</>
	)
}