import React from 'react'

import ActionButton from '../ActionButton'

import {
	faShare as declareIcon,
	faArrowUp as highIcon,
	faArrowDown as lowIcon
} from '@fortawesome/free-solid-svg-icons'

class DeclareComponent extends React.Component {

	state = {
		pots: [],
			
		options: [{
			parameter: 'high',
			value: false
		},{
			parameter: 'low',
			value: false
		}]
	}
	
	onChange(value, option) {
		option.value = value;
		
		let pots = this.state.options.filter(o => o.value).map(o => o.parameter);
		
		this.setState({ pots });
	}

	submit(pots) {
		let { hand } = this.props;

		hand.action('declare', { pots });
	}

	render() {
		let { pots, options } = this.state;
		
		return (
			<div className="d-flex">
			  <ActionButton icon={highIcon} variant="outline-secondary" tooltip="high" active={options[0].value} onClick={e => this.onChange(!options[0].value, options[0])} />
			  <div className="mx-2"><ActionButton icon={lowIcon} variant="outline-secondary" tooltip="low" active={options[1].value} onClick={e => this.onChange(!options[1].value, options[1])} /></div>
			  <div classNamr="mx-1"><ActionButton icon={declareIcon} variant="primary" tooltip="declare" disabled={pots.length === 0} onClick={z => this.submit(pots)} player={this.props.player} isDefault={this.props.isDefault}/></div>
	  	</div>
		)
	}
	
}

export default DeclareComponent;