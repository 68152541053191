import React from 'react';

import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

import ConditionalWrapper from '../ConditionalWrapper';

import env from '../env';

import './SelectableCard.css';

const DOWN_IMAGE_SOURCE = env.imageUrl("/deck/red_back.png");
const SPACER_IMAGE_SOURCE = env.imageUrl("/deck/spacer.png");
const BACK_MASK_SOURCE = env.imageUrl("/deck/red_back_mask.png");

function noOp() {}

const maskImage = <Image className="Card CardBackMask" src={BACK_MASK_SOURCE} />;
const maskTooltip = <Tooltip>This card is facedown to your opponents</Tooltip>;
const downCard = <div className="Card PlayingCard flip-card-front"><Image className="Card" src={DOWN_IMAGE_SOURCE} /></div>;

function SelectableCard(props) {
	let { card, selected, selectable, masked, cardSize, defaultClass, onClick } = props;
	onClick = (selectable || selected) && onClick ? onClick : noOp;

	let className = 'Card PlayingCard';
	if (defaultClass) className += " " + defaultClass;

	let cardContainerClassName = 'CardContainer';
	if (selectable) cardContainerClassName += ' PlayingCardSelectable';
	if (selected) cardContainerClassName += ' PlayingCardSelected';
	
	if (card.hasOwnProperty('inBestHand')) {
		cardContainerClassName += card.inBestHand ? card.inWinningHand ? ' in-winning-hand' : ' in-best-hand' : ' not-in-best-hand';
	} else if (card.pushKeep) cardContainerClassName += ' push-keep';

	let flipCardStyle = `card-sizer flip-card-inner ${card.id ? 'flip' : ''}`;

	return (
		<ConditionalWrapper condition={masked}
			wrapper={children => <OverlayTrigger placement='top' overlay={maskTooltip}>{children}</OverlayTrigger>}
			elseDisplayChildren={true}>
			<div className={cardContainerClassName} onClick={z => onClick(card)}>
				<div className={flipCardStyle} style={cardSize}>
					<EmptySpacer className="Card"/>
					<div className="flip-card-back">
						{ card.id && <Image className={className} src={env.imageUrl(`/deck/${card.id.toUpperCase()}.png`)} /> }
						{ masked && !selected && maskImage }
					</div>
					{ downCard }
				</div>
			</div>
		</ConditionalWrapper>
	)
}

function EmptySpacer(props) {
	return <Image className={props.className} src={SPACER_IMAGE_SOURCE}/>;
}

export { SelectableCard, EmptySpacer, maskImage, downCard };