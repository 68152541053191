import React from 'react';

import { Card, Button, Badge } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFilter as applyFilter,
	faTimesCircle as removeFilter,
	faPlayCircle as start,
	faAngleRight as next,
	faAngleLeft as previous,
} from '@fortawesome/free-solid-svg-icons'

var styles = {
	container: {
		// backgroundColor: 'red'
	},

	card: {
		width: '70%'
	},

	gameTag: {
		variation: {
			variant: 'primary'
		},
		limit: {
			variant: 'secondary'},
		pots: {
			variant: 'info'},
		low: {
			variant: 'warning'},
	}
}

function filterGames(games, category, value) {
	return games.filter(g => {
		var tag = g.tags.find(t => t.category === category);

		return tag ? tag.value === value : false;
	})
}

function FilterButton(props) {
	var { on, label, variant, gameCount } = props;

	return (
		<Button className="mx-2" variant={on ? variant : `outline-${variant}`} onClick={props.onClick} disabled={props.disabled || gameCount === 0}>
	      <Badge variant="success">{ gameCount }</Badge>
		  <span className="px-3">{label}</span>
		  <FontAwesomeIcon icon={on ? removeFilter : applyFilter} rotation={props.rotation} inverse />
		</Button>
	)
}

class GameFilters extends React.Component {

	render() {
		var { games, filters, applyFilter } = this.props;

		var variatonFilters = filters.slice(0, 4);
		var lowFilters = filters.slice(4);

		return (
			<div className="mb-3 d-flex flex-column justify-content-center">
				<div className="w-100 d-flex justify-content-center">
				{
				variatonFilters.map(f => {
					var filteredGames = f.filter(games);

					return (
						<FilterButton
							key={f.label}
							on={f.on}
							disabled={false}
							variant="secondary"
							label={f.label}
							gameCount={filteredGames.length}
							onClick={z => applyFilter(f)} />
					)						
				})
			}

				</div>
				<div className="mt-2 w-100 d-flex justify-content-center">
			{
				lowFilters.map(f => {
					var filteredGames = f.filter(games);

					return (
						<FilterButton
							key={f.label}
							on={f.on}
							disabled={false}
							variant="secondary"
							label={f.label}
							gameCount={filteredGames.length}
							onClick={z => applyFilter(f)} />
					)						
				})
			}
			</div>
		</div>
		)
	}

}

function GameCard(props) {
	var { game, onPlay, disabled } = props;

	if (!game) return <div></div>;
	
	onPlay = onPlay || function() {};
	var tags = game.tags || [];

	return (
		<Card style={styles.card}>
		  <Card.Body>
	        <Card.Title>{ game.title }</Card.Title>
	        <Card.Text>{game.description}</Card.Text>
		    <Button disabled={disabled} block variant="success" size="lg" onClick={z => onPlay()}>
			  <FontAwesomeIcon icon={start} inverse />
			  <div className="text-bold mt-1" style={{ fontSize: 12 }}>DEAL GAME</div>
			</Button>
		  </Card.Body>
		  <Card.Footer className="text-muted">
			<div className="d-flex justify-content-end">
			  {
				  tags.map(t => <Badge key={t.value} className="ml-1 px-2" pill variant={styles.gameTag[t.category].variant}>{t.label}</Badge>)
			  }
			</div>
		  </Card.Footer>
	  </Card>
	)
}

class GameCarousel extends React.Component {

	state = {
		currentIndex: -1,
		disabled: false,
		filters: [{
			label: "Hold'em",
			filter: games => filterGames(games, 'variation', 'holdem')
		}, {
			label: "Omaha",
			filter: games => filterGames(games, 'variation', 'omaha')
		}, {
			label: "Stud",
			filter: games => filterGames(games, 'variation', 'stud')
		}, {
			label: "Draw",
			filter: games => filterGames(games, 'variation', 'draw')
		}, {
			label: "6-4 Low",
			filter: games => filterGames(games, 'low', '64')
		}, {
			label: "5-4 Low",
			filter: games => filterGames(games, 'low', '54')
		}],
		filteredGames: [].concat(this.props.games)
	}

	applyFilter(f) {
		f.on = f.on ? false : true;

		var onFilters = this.state.filters.filter(f => f.on);

		let filteredGames = [].concat(this.props.games);

		onFilters.forEach(f => {
			filteredGames = f.filter(filteredGames)
		})

		this.setState({
			filters: this.state.filters,
			filteredGames: filteredGames,
			currentIndex: 0,
			currentGame: filteredGames.length > 0 ? filteredGames[0] : undefined
		});
	}

	selectGame(gameId) {
		var game = this.props.games.find(g => g.id === gameId);

		var { onGameSelected } = this.props;
		if (onGameSelected) onGameSelected(game);
	}

	nextGame() {
		var games = this.state.filteredGames;
		var { currentIndex } = this.state;

		var newIndex = currentIndex < 0 || currentIndex === games.length - 1 ? 0 : currentIndex + 1;

		this.setState({ currentIndex: newIndex, currentGame: games[newIndex] });
	}

	previousGame() {
		var games = this.state.filteredGames;
		var { currentIndex } = this.state;

		var newIndex = currentIndex <= 0 ? games.length - 1 : currentIndex - 1;

		this.setState({ currentIndex: newIndex, currentGame: games[newIndex] });
	}

	playGame() {
		this.props.onPlay(this.state.currentGame);

		this.setState({ disabled: true })
	}

	componentDidMount() {
		var games = this.state.filteredGames;

		if (games && games.length > 0) {
			this.nextGame();

			this.selectGame(games[0].id);
		}
	}

	render() {
		var { disabled, filteredGames } = this.state;

		var navButtonsDisabled = disabled || filteredGames.length < 2;

		var gameFilters = <GameFilters games={filteredGames} filters={this.state.filters} applyFilter={f => this.applyFilter(f)} />

		if (filteredGames.length === 0) return (
			<div className="d-flex flex-column justify-content-center" style={styles.container}>
			  { gameFilters }

		      <div className="mt-2 w-100 d-flex flex-column justify-content-center align-items-center" style={{ color: 'white', fontSize: '2em' }}>
				  <div>NO GAMES MATCH SELECTED FILTERS</div>
				  <div className="mt-3" style={{ color: 'white' }}>DE-SELECT ONE OR MORE FILTER</div>
			  </div>
			</div>
		)

		return (
			<div className="d-flex flex-column justify-content-center" style={styles.container}>
			  { gameFilters }

			  <div className="mt-1 d-flex justify-content-center align-items-center">
				  <Button disabled={navButtonsDisabled} className="m-2" variant="outline-secondary" onClick={z => this.previousGame()}>
					<FontAwesomeIcon icon={previous} />
				  </Button>
				  <GameCard disabled={disabled} game={this.state.currentGame} onPlay={z => this.playGame()}/>
				  <Button disabled={navButtonsDisabled} className="m-2" variant="outline-secondary" onClick={z => this.nextGame()}>
					<FontAwesomeIcon icon={next} />
				  </Button>
			  </div>

		      <div className="mt-2 text-muted d-flex justify-content-center" style={{ color: 'white', fontSize: '1.5em' }}>
				  {this.state.currentIndex + 1} OF {this.state.filteredGames.length} GAMES
			  </div>
			</div>
			)
	}

}

export default GameCarousel;